import React, { useState, useEffect } from "react";
import _ from "lodash";
import { compose } from "redux";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
	Form,
	Input,
	FormGroup,
	Label,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ConfigCategoryForm = ({
	onSubmit,
	selectedConfigCategory,
}) => {
	const [configCategoryForm, setConfigCategoryForm] = useState({
		name: ''
	});

	const onChangeField = (key, val) => {
		setConfigCategoryForm({
			...configCategoryForm,
			[key]: val,
		});
	};

	useEffect(() => {
		if (selectedConfigCategory) {
			setConfigCategoryForm({
				...selectedConfigCategory,
			});
		}
	}, [selectedConfigCategory]);

	return (
		<>
			<CustomModalBody>
				<Form>
					<FormGroup>
						<Label>
							Name
							<span className="text-danger">*</span>
						</Label>
						<Input
							placeholder="Add Category Name"
							value={configCategoryForm.name}
							onChange={(e) => onChangeField("name", e.target.value)}
						/>
					</FormGroup>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "primary",
						content: "Save",
						disabled: !configCategoryForm.name,
						onClick: () => {
							onSubmit({ ...configCategoryForm });
						},
					},
				]}
			/>
		</>
	);
};

export default compose()(ConfigCategoryForm);
