import { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { compose } from "redux";

import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import TemplateContainerMain from "components/Template";
import Bin from "./Bin";
import CarBrand from "./CarBrand";
import CarModel from "./CarModel";
import ConfigCategory from "./ConfigCategory";
import ConfigCategoryItems from "./ConfigCategoryItems";
import CarModelVariant from "./CarModelVariant";
import Petrol from "./Petrol";
import PostMngmt from "./Post";
import Video from "./Video";

import JobMachaBin from "./JobMacha/Bin";
import JobMachaPost from "./JobMacha/Post";
import JobMachaTags from "./JobMacha/Tags";

import ServiceMachaBin from "./ServiceMacha/Bin";
import ServiceMachaPost from "./ServiceMacha/Post";
import ServiceMachaTags from "./ServiceMacha/Tags";

import { getItem } from "utils/tokenStore";

import "react-toastify/dist/ReactToastify.css";

const Tags = lazy(() => import("./Tags"));
const Categories = lazy(() => import("./Categories"));
const DataDrive = lazy(() => import("./DataDrive"));
const FeaturedModel = lazy(() => import("./FeaturedModel"));

const SuspenseWrapper = ({ children }) => (
	<Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
);

class Dashboard extends Component {
	componentDidMount = () => {
		if (!getItem("CHINA_PRESS_TOKEN")) {
			this.props.history.push("/login");
		} else {
			const script = document.createElement("script");
			script.src = "https://platform.twitter.com/widgets.js";
			script.charset = "utf-8";
			script.async = true;

			// Append the script to the document's head
			document.head.appendChild(script);
		}
	};

	render = () => {
		return (
			<>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => { }}
						history={this.props.history}
						user={this.props.data.ProfileReducer.profile}
					/>
					<div className="app-main">
						<TemplateSidemenu
							userPermissions={this.props.userPermissions}
							user={this.props.user}
							userRole={this.props.userRole}
						/>
						<div className={`app-main__outer`}>
							<div className={`app-main__inner`}>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/post`}
										render={(props) => <PostMngmt {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/tags`}
										render={(props) => <Tags {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/configcategory`}
										render={(props) => <ConfigCategory {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/configcategoryitems/:key`}
										render={(props) => <ConfigCategoryItems {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/categories`}
										render={(props) => <Categories {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/drive`}
										render={(props) => <DataDrive {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/video`}
										render={(props) => <Video {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/bin`}
										render={(props) => <Bin {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/car-brand`}
										render={(props) => <CarBrand {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/car-model`}
										render={(props) => <CarModel {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/car-model-variant`}
										render={(props) => <CarModelVariant {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/petrol`}
										render={(props) => <Petrol {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/feature-car-model`}
										render={(props) => <FeaturedModel {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/job-macha/post`}
										render={(props) => <JobMachaPost {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/job-macha/bin`}
										render={(props) => <JobMachaBin {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/job-macha/tags`}
										render={(props) => <JobMachaTags {...props} />}
									/>
								</SuspenseWrapper>

								<SuspenseWrapper>
									<Route
										path={`/dashboard/service-macha/post`}
										render={(props) => <ServiceMachaPost {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/service-macha/bin`}
										render={(props) => <ServiceMachaBin {...props} />}
									/>
								</SuspenseWrapper>
								<SuspenseWrapper>
									<Route
										path={`/dashboard/service-macha/tags`}
										render={(props) => <ServiceMachaTags {...props} />}
									/>
								</SuspenseWrapper>
							</div>
						</div>
					</div>
					<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
				</TemplateContainerMain >
			</>
		);
	};
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
	connect(mapStateToProps, {
		withRouter,
	})
)(Dashboard);
