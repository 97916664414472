import React, { useEffect, Fragment } from "react";
import _ from "lodash";
import {
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Row,
} from "reactstrap";
import PostCategoryHOC from "../actions/postCategory";

function Categories({
	selectedVideo,
	postCategory,
	categories,
	getPostCategory,
	createPostCategory,
	deletePostCategory,
}) {
	useEffect(() => {
		getPostCategory(selectedVideo.id);
	}, []);
	return (
		<Card>
			<CardBody>
				<Label className="mb-2">Post Categories</Label>
				<hr />
				<Form>
					<Row>
						{categories.map((categoryChild) => (
							<Col xl={3} lg={4} md={6} key={`category_${categoryChild.id}`}>
								<FormGroup>
									<Label>
										<strong>{categoryChild.name}</strong>
									</Label>

									{categoryChild.children.map((subChild) => (
										<Fragment key={`category_child_${subChild.id}`}>
											<FormGroup style={{ marginLeft: "1.25rem" }}>
												<Input
													type={"checkbox"}
													color="primary"
													checked={
														_.findIndex(postCategory, {
															mywheels_category_id: subChild.id,
														}) > -1
													}
													onChange={() => {
														let tempIndex = _.findIndex(
															postCategory,
															(child) => {
																return (
																	child.mywheels_category_id === subChild.id
																);
															}
														);

														tempIndex < 0
															? createPostCategory({
																	post_id: selectedVideo.id,
																	mywheels_category_id: subChild.id,
															  })
															: postCategory[tempIndex]?.id &&
															  deletePostCategory(
																	postCategory[tempIndex].id,
																	postCategory[tempIndex].post_id
															  );
													}}
												/>
												<Label>{subChild.name}</Label>
											</FormGroup>
										</Fragment>
									))}
								</FormGroup>
							</Col>
						))}
					</Row>
				</Form>
			</CardBody>
		</Card>
	);
}

export default PostCategoryHOC(Categories);
