import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			selectedPostVideo: [],
		};

		onChangeSampleHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });
		getPostVideo = (id) =>
			Get(
				`/videos?post_id=${id}`,
				this.getPostVideoSuccess,
				this.getPostVideoError,
				this.load
			);
		getPostVideoSuccess = (payload) =>
			this.setState({
				selectedPostVideo: payload?.data ?? [],
			});
		getPostVideoError = (error) => requestError(error);

		updatePostVideo = (payload, id ) =>{
			Put(
				`/videos/${id}`,
				{
					... payload,
					id
				},
				() => this.updatePostVideoSuccess(payload.post_id),
				this.updatePostVideoError,
				this.load
			);
		}
		updatePostVideoSuccess = (postID) => {
			this.getPostVideo(postID);
		};
		updatePostVideoError = (error) => requestError(error);

		deletePostVideo = (id, postID) =>
			Delete(
				`/videos/${id}`,
				() => this.deletePostVideoSuccess(postID),
				this.deletePostVideoError,
				this.load
			);
		deletePostVideoSuccess = (postID) => {
			this.getPostVideo(postID);
		};
		deletePostVideoError = (error) => requestError(error);

		createPostVideo = (payload) =>
			Post(
				`/videos`,
				payload,
				() => this.createPostVideoSuccess(payload.post_id),
				this.createPostVideoError,
				this.load
			);
		createPostVideoSuccess = (postID) => {
			this.getPostVideo(postID);
		};
		createPostVideoError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					selectedPostVideo={this.state.selectedPostVideo}
					getPostVideo={this.getPostVideo}
					updatePostVideo={this.updatePostVideo}
					deletePostVideo={this.deletePostVideo}
					createPostVideo={this.createPostVideo}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
