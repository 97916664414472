import React, { useState, useEffect } from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";

import { Input, FormGroup, Label, Button } from "reactstrap";

export default function Form({ selectedPetrol, onSubmit }) {
  const [tempStart, setStart] = useState( null )
  const [tempEnd, setEnd] = useState( null )
  const [tempContent,setContent] = useState([])

  useEffect(() => {
    if ( selectedPetrol ) {
      setStart( selectedPetrol.start_at )
      setEnd( selectedPetrol.end_at )
      setContent( selectedPetrol.content )
    }
  }, [ selectedPetrol ])

	return (
		<>
      <FormGroup>
        <Label>Start At</Label>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          showYearDropdown
          selected={
            tempStart === null ? null : new Date(tempStart)
          }
          className="form-control"
          onChange={(val) => setStart( val )}
        />
      </FormGroup>
      <FormGroup>
        <Label>End At</Label>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          showYearDropdown
          selected={
            tempEnd === null ? null : new Date(tempEnd)
          }
          className="form-control"
          onChange={(val) => setEnd( val )}
        />
      </FormGroup>
      {
        tempContent.map(( contentChild, contentIndex ) => (
          <FormGroup>
            <Label>{ contentChild.type }</Label>
            <Input value={ contentChild.price } onChange={(e) => {
              let temp = _.cloneDeep( tempContent )
              temp[ contentIndex ].price = e.target.value

              setContent( temp )
            }} />
          </FormGroup>
        ))
      }

      <div className="d-flex mt-4">
        <Button
          color={ 'primary' }
          onClick={ () => onSubmit({
            ... selectedPetrol,
            content: tempContent,
            start_at: tempStart,
            end_at: tempEnd
          })}
        >
          Submit
        </Button>
      </div>

		</>
	);
}
