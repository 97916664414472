import React, { useState, useEffect } from "react";
import _ from "lodash";
import { compose } from "redux";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
	Form,
	Input,
	FormGroup,
	Label,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ConfigCategoryItemsForm = ({
	onSubmit,
	selectedConfigCategoryItems,
}) => {
	const [configCategoryItemsForm, setConfigCategoryItemsForm] = useState({
		name: ''
	});

	const onChangeField = (key, val) => {
		setConfigCategoryItemsForm({
			...configCategoryItemsForm,
			[key]: val,
		});
	};

	useEffect(() => {
		if (selectedConfigCategoryItems) {
			setConfigCategoryItemsForm({
				...selectedConfigCategoryItems,
			});
		}
	}, [selectedConfigCategoryItems]);

	return (
		<>
			<CustomModalBody>
				<Form>
					<FormGroup>
						<Label>
							Name
							<span className="text-danger">*</span>
						</Label>
						<Input
							placeholder="Add Item Name"
							value={configCategoryItemsForm.name}
							onChange={(e) => onChangeField("name", e.target.value)}
						/>
					</FormGroup>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "primary",
						content: "Save",
						disabled: !configCategoryItemsForm.name,
						onClick: () => {
							onSubmit({ ...configCategoryItemsForm });
						},
					},
				]}
			/>
		</>
	);
};

export default compose()(ConfigCategoryItemsForm);
