import React from "react";
import { Modal, ModalHeader } from "reactstrap";

import "./index.scss";

export default function CustomModal({
	isOpen,
	fullScreen,
	children,
	className,
	size,
	title,

	onClose,
}) {
	return (
		<>
			<Modal
				centered={true}
				className={`${className || ""} china-press-dialog ${
					fullScreen ? "china-press-dialog-full_screen" : ""
				}`}
				isOpen={isOpen}
				size={size}
				style={{
					position: "relative",
					height: fullScreen ? "max-height" : "initial",
				}}>
				<ModalHeader toggle={onClose ? () => onClose() : false}>{title}</ModalHeader> 
				{children}
			</Modal>
		</>
	);
}
