import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			brands: [],
			models: [],
			selectedModel: {},
			showCreateModelModal: false,
			showEditModelModal: false,
			searchParams: [
				{
					label: "Title",
					value: `title`,
					type: "text",
					param: ``,
					col: 6,
				},
				{
					label: "Brands",
					value: `mywheels_car_brand_id`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				},
			],
		};

		onChangeModelHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBrand = () => {
			Get(
				`/mywheels/car-brands`,
				this.getBrandSuccess,
				this.getBrandError,
				this.load
			);
		};

		getBrandSuccess = (payload) => this.setState({ brands: payload.data });
		getBrandError = (error) => requestError(error);

		getModel = (val) => {
			Get(
				`/mywheels/car-brand-models?${val}`,
				this.getModelSuccess,
				this.getModelError,
				this.load
			);
		};

		getModelSuccess = (payload) => this.setState({ models: payload.data });
		getModelError = (error) => requestError(error);

		getSelectedModel = (id) =>
			Get(
				`/mywheels/car-brand-models`,
				this.getSelectedModelSuccess,
				this.getSelectedModelError,
				this.load
			);
		getSelectedModelSuccess = (payload, image) =>
			this.setState({ selectedModel: payload });
		getSelectedModelError = (error) => requestError(error);

		handleUploadModelImage = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/car-model-images`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		createModel = (dataToSubmit, imageData) =>
			Post(
				`/mywheels/car-brand-models`,
				dataToSubmit,
				(payload) => this.createModelSuccess(payload, imageData),
				this.createModelError,
				this.load
			);
		createModelSuccess = (payload, imageData) => {
			let tempApiCall = [];
			for (let index = 0; index < imageData.length; index++) {
				tempApiCall.push(
					this.handleUploadModelImage({
						...imageData[index],
						mywheels_car_brand_model_id: payload.data.id,
					})
				);
			}

			this.setState({ showCreateModelModal: false });
			this.getModel();
			requestSuccess("Model has been created successfully.");
		};
		createModelError = (error) => requestError(error);

		updateModel = (dataToSubmit, id) =>
			Put(
				`/mywheels/car-brand-models/${id}`,
				dataToSubmit,
				this.updateModelSuccess,
				this.updateModelError,
				this.load
			);
		updateModelSuccess = (payload) => {
			this.getModel();
			this.setState({ showEditModelModal: false });
			requestSuccess("Model has been updated successfully.");
		};
		updateModelError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					brands={this.state.brands}
					models={this.state.models}
					selectedModel={this.state.selectedModel}
					onLoadModel={this.state.loading}
					showCreateModelModal={this.state.showCreateModelModal}
					showEditModelModal={this.state.showEditModelModal}
					searchParams={this.state.searchParams}
					getModel={this.getModel}
					getSelectedModel={this.getSelectedModel}
					createModel={this.createModel}
					getBrand={this.getBrand}
					updateModel={this.updateModel}
					onChangeModelHOC={this.onChangeModelHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
