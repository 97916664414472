export const SampleData = {
	mywheels_car_brand_id: null,
	reviews: "",
	reviews_heading: "",
	title: "",
	must_read_post_id: null,
	featured_photo: "",
	pros: [],
	cons: [],
	status: "active",
};
