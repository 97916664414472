import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Put, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { queryHandler } from "utils/queryHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			posts: [],
			postCounts: 0,
			pageIndex: 1,
			selectedDeletePost: null,
			selectedPost: null,
			showEditPostModal: false,
			showCreatePostModal: false,
			searchParams: [
				{
					label: "Title",
					value: `title`,
					type: "text",
					param: ``,
					col: 6,
				},
				{
					label: "Category",
					value: `slug_category_id`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				},
				{
					label: "From Date (Published)",
					value: `published_datetype=between&published_start_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
				{
					label: "To Date (Published)",
					value: `published_end_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
				{
					label: "From Date (Scheduled)",
					value: `datetype=between&start_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
				{
					label: "To Date (Scheduled)",
					value: `end_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
			],
		};

		onChangePostHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getImageUrl = (id) => {
			return new Promise((resolve, reject) => {
				Get(
					`/get-wagtail-images/${id}`,
					(payload) => resolve(payload.data.file),
					(error) => reject(error),
					this.load
				);
			});
		};

		getPost = (val) => {
			Get(
				`/ec/non-deleted-posts?${
					!val
						? `type=post&platform=jobmacha&offset=${
								(this.state.pageIndex - 1) * 10
						  }&limit=10`
						: `type=post&platform=jobmacha${val}&offset=${
								(this.state.pageIndex - 1) * 10
						  }&limit=10`
				}`,
				this.getPostSuccess,
				this.getPostError,
				this.load
			);
		};
		getPostSuccess = (payload) => {
			this.setState({
				posts: payload.data.rows,
				postCounts: payload.data.count,
			});
		};
		getPostError = (error) => requestError(error);

		getSelectedPost = (val) => {
			Get(
				`/ec/posts/single?slug=${val}`,
				this.getSelectedPostSuccess,
				this.getSelectedPostError,
				this.load
			);
		};
		getSelectedPostSuccess = (payload) => {
			// Create a temporary div to parse the HTML
			let tempApiCall = [];
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = payload.data.content;

			// Apply the logic to modify the HTML
			const embeddedImages = tempDiv.querySelectorAll('[embedtype="image"]');

			for (let index = 0; index < embeddedImages.length; index++) {
				tempApiCall.push(this.getImageUrl(embeddedImages[index].id));
			}

			Promise.all(tempApiCall)
				.then((res) => {
					embeddedImages.forEach(async (embeddedImage, index) => {
						const imgElement = document.createElement("img");

						imgElement.src = `https://mywheels-production.s3.amazonaws.com/media/${res[index]}`;

						const attributes = embeddedImage.attributes;
						if ((attributes?.format?.value).indexOf("fullwidth") > -1) {
							imgElement.style.width = "100%";
						}
						for (let i = 0; i < attributes.length; i++) {
							const attr = attributes[i];
							if (attr.name !== "embedtype" && attr.name !== "embedsrc") {
								imgElement.setAttribute(attr.name, attr.value);
							}
						}

						if (attributes?.alt?.value) {
							const figureElement = document.createElement("figure");
							figureElement.appendChild(imgElement);
							figureElement.classList.add("image", "image_resized");

							const figcaptionElement = document.createElement("figcaption");
							figcaptionElement.innerText = attributes.alt.value;
							figureElement.appendChild(figcaptionElement);

							embeddedImage.parentNode.replaceChild(
								figureElement,
								embeddedImage
							);
						} else {
							embeddedImage.parentNode.replaceChild(imgElement, embeddedImage);
						}
					});

					this.setState({
						selectedPost: {
							...payload.data,
							content: tempDiv.innerHTML,
							mywheels_tags: [],
						},
						showEditPostModal: true,
					});
				})
				.catch((err) => {
					requestError(err);
				});
		};
		getSelectedPostError = (error) => requestError(error);

		updatePost = (dataToSubmit) => {
			Put(
				`/posts/${dataToSubmit.id}`,
				{
					...dataToSubmit,
					name: JSON.stringify(dataToSubmit.name),
				},
				this.updatePostSuccess,
				this.updatePostError,
				this.load
			);
		};
		updatePostSuccess = () => {
			requestSuccess("Record has been updated successfully.");
			let tempQeuery = queryHandler(this.state.searchParams);

			this.setState({
				selectedDeletePost: null,
			});

			this.getPost(tempQeuery);
		};
		updatePostError = (error) => requestError(error);

		handleCarModel = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/post/car-brand-models`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		handleCarBrands = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/post/car-brands`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		handleUploadPostImage = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/post-galleries`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		handleCreatePostVideo = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/videos`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		handleCreatePostCat = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/categories-posts`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		createPost = (dataToSubmit, postVideos, postCategories, imageData) => {
			Post(
				`/posts`,
				{
					...dataToSubmit,
					name: JSON.stringify(dataToSubmit.name),
				},
				(payload) =>
					this.createPostSuccess(
						payload,
						postVideos,
						postCategories,
						imageData
					),
				this.createPostError,
				this.load
			);
		};
		createPostSuccess = (payload, postVideos, postCategories, imageData) => {
			if (postCategories.length > 0) {
				let postCatApi = [];
				for (let index = 0; index < postCategories.length; index++) {
					postCatApi.push(
						this.handleCreatePostCat({
							...postCategories[index],
							post_id: payload.data.id,
						})
					);
				}
				Promise.all(postCatApi)
					.then((responses) => {
						console.log(responses);
					})
					.catch((error) => {
						console.error(error);
					});
			}

			if (postVideos.length > 0) {
				let postVideoApi = [];
				for (let index = 0; index < postVideos.length; index++) {
					postVideoApi.push(
						this.handleCreatePostVideo({
							post_id: payload.data.id,
							url: postVideos[index]["url"],
						})
					);
				}

				// video create
				Promise.all(postVideoApi)
					.then((responses) => {
						console.log(responses);
					})
					.catch((error) => {
						console.error(error);
					});
			}

			if (imageData.length > 0) {
				let tempApiCall = [];

				for (let index = 0; index < imageData.length; index++) {
					tempApiCall.push(
						this.handleUploadPostImage({
							...imageData[index],
							post_id: payload.data.id,
						})
					);
				}
			}

			requestSuccess("Record has been created successfully.");
			this.setState({ showCreatePostModal: false }, () => {
				this.getSelectedPost(payload.data.slug);
			});
			let tempQeuery = queryHandler(this.state.searchParams);

			this.getPost(tempQeuery);
		};
		createPostError = (error) => requestError(error);

		addFurtherPost = (dataToSubmit, postSlug) => Post(
			`/post-further-readings`,
			dataToSubmit,
			() => {
				this.getSelectedPost(postSlug)
				requestSuccess( 'Further Reading Post added successfully.' )
			},
			(error) => requestError(error),
			this.load
		)
		

		deleteFutherPost = (id, postSlug) => Delete(
			`/post-further-readings/${id}`,
			() => {
				this.getSelectedPost(postSlug)
				requestSuccess( 'Further Reading Post deleted successfully.' )
			},
			(error) => requestError(error),
			this.load
		)

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCounts={this.state.postCounts}
					pageIndex={this.state.pageIndex}
					selectedDeletePost={this.state.selectedDeletePost}
					posts={this.state.posts}
					searchParams={this.state.searchParams}
					showCreatePostModal={this.state.showCreatePostModal}
					showEditPostModal={this.state.showEditPostModal}
					selectedPost={this.state.selectedPost}
					onLoadPost={this.state.loading}
					addFurtherPost={this.addFurtherPost}
					deleteFutherPost={this.deleteFutherPost}
					getPost={this.getPost}
					updatePost={this.updatePost}
					createPost={this.createPost}
					onChangePostHOC={this.onChangePostHOC}
					getSelectedPost={this.getSelectedPost}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
