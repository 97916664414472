import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { RootCategories } from "../assets";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			categories: [],
			flattenCategories: [],
		};

		onChangeSampleHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getCategories = () => {
			Get(
				`/servicemacha/categories`,
				this.getCategoriesSuccess,
				this.getCategoriesError,
				this.load
			);
		};
		getCategoriesSuccess = (payload) => {
			let tempData = [...payload.data];
			tempData.splice(0, 1);
			this.setState({
				categories: tempData,
			});
		};
		getCategoriesError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					onLoadCategory={this.state.loading}
					getCategories={this.getCategories}
					categories={this.state.categories}
					flattenCategories={this.state.flattenCategories}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
