import _ from "lodash";
import Moment from "moment";

export const queryHandler = (searchParams) => {
	let tempSearch = _.cloneDeep(searchParams);
	let tempQuery = ``;

	tempSearch.map((searchChild) => {
		if (searchChild.param) {
			if (searchChild.type === "date") {
				tempQuery += `${tempQuery === "?" ? "" : "&"}${
					searchChild.value
				}=${Moment(searchChild.param).format("YYYY-MM-DD")}`;
			} else {
				tempQuery += `${tempQuery === "?" ? "" : "&"}${searchChild.value}=${
					searchChild.param
				}`;
			}
		}
	});

	return tempQuery === "?" ? "" : tempQuery;
};
