import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { Button } from 'reactstrap'

const PageTitle = ({
  enablePageTitleIcon,
  enablePageTitleSubheading,
  icon,
  heading,
  subheading,
  buttons,
  customButton
}) => {
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          {
            icon && (
              <div className={ cx("page-title-icon", {'d-none': !enablePageTitleIcon}) }>
                <i className={ icon }/>
              </div>
            )
          }
          <div className="page-title">
            { heading }
            <div className={ cx("page-title-subheading", {'d-none': !enablePageTitleSubheading}) }>
              { subheading }
            </div>
          </div>
        </div>
        <div className="page-title-actions">
          {
            buttons?.[0] && buttons.map(( item, index ) => {
              return (
                <Button 
                  color={ item.color } 
                  className={ item.className || '' } 
                  onClick={ item.onClick } key={ index }>
                  { item.content }
                </Button>
              )
            })
          }
          {
            customButton && (
              customButton
            )
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
})

export default connect( mapStateToProps )( PageTitle )