import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			tags: [],
			selectedTag: null,
			showEditTagModal: false,
			showCreateTagModal: false,
		};

		onChangeTagHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getTag = () =>
			Get(`/ec/tags`, this.getTagSuccess, this.getTagError, this.load);
		getTagSuccess = (payload) => {
			this.setState({
				tags: payload.data,
			});
		};
		getTagError = (error) => requestError(error);

		updateTag = (dataToSubmit) => {
			Put(
				`/ec/tags/${dataToSubmit.id}`,
				dataToSubmit,
				this.updateTagSuccess,
				this.updateTagError,
				this.load
			);
		};
		updateTagSuccess = () => {
			this.getTag();
			requestSuccess("Record updated successfully.");
		};
		updateTagError = (error) => requestError(error);

		createTag = (dataToSubmit) => {
			Post(
				`/ec/tags`,
				dataToSubmit,
				this.createTagSuccess,
				this.createTagError,
				this.load
			);
		};
		createTagSuccess = () => {
			this.getTag();
			this.setState({ showCreateTagModal: false });
			requestSuccess("Record created successfully.");
		};
		createTagError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					tags={this.state.tags}
					showCreateTagModal={this.state.showCreateTagModal}
					showEditTagModal={this.state.showEditTagModal}
					selectedTag={this.state.selectedTag}
					onLoadTag={this.state.loading}
					getTag={this.getTag}
					updateTag={this.updateTag}
					createTag={this.createTag}
					onChangeTagHOC={this.onChangeTagHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
