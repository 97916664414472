import React, { useState, useEffect } from "react";
import _ from "lodash";
import { AiFillEdit } from "react-icons/ai";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";
import {
  Input,
  FormGroup,
  Label,
  Form,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { SampleData } from "./assets";
import CustomModal from "components/Modal";
import PostModal from "./PostModal";
import ImageField from "./Image";
import LoadingIndicator from "components/Indicator/LoadingOverlay";
import RTE from "components/Input/RTE";
import FeaturedImageField from "components/Input/File";

import ImageHOC from "./actions/image.js";

const ModelForm = ({
  selectedModel,
  onSubmit,
  brands,
  onLoadImage,
  getImage,
  images,
  deleteImage,
  createImage,
}) => {
  const [tempForm, setTempForm] = useState(SampleData);
  const [tempSelectedPost, setTempSelectedPost] = useState(null);
  const [showChoosePost, setChoosePost] = useState(false);
  const [conItem, setConItem] = useState("");
  const [proItem, setProItem] = useState("");

  useEffect(() => {
    if (selectedModel) {
      setTempForm(selectedModel);
      getImage(selectedModel.id);
      setTempSelectedPost(selectedModel.mywheels_must_read_post);
    }
  }, [selectedModel]);

  const onChangeField = (key, val) => {
    let temp = _.cloneDeep(tempForm);
    temp[key] = val;

    setTempForm(temp);
  };

  return (
    <>
      <ChinaPressModalBody>
        <Form>
          <Row>
            <Col xl={6}>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  value={tempForm["title"]}
                  type={"text"}
                  onChange={(e) => onChangeField("title", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Label>Car Brand</Label>
                <Input
                  type={"select"}
                  value={tempForm["mywheels_car_brand_id"]}
                  onChange={(e) =>
                    onChangeField("mywheels_car_brand_id", +e.target.value)
                  }
                >
                  <option value=""></option>
                  {brands.map((brandChild) => (
                    <option value={brandChild.id} key={brandChild.id}>
                      {brandChild.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FeaturedImageField
                  storagePath="car_model"
                  label={"Featured Photo"}
                  fileValue={tempForm["featured_photo"]}
                  onRemoveFile={() => onChangeField("featured_photo", "")}
                  onSelectImage={(val) => {
                    onChangeField("featured_photo", val);
                  }}
                />
              </FormGroup>
            </Col>
            <Col xl={12}>
              <FormGroup>
                <Label>Reviews Heading</Label>
                <Input
                  value={tempForm["reviews_heading"]}
                  type={"text"}
                  onChange={(e) =>
                    onChangeField("reviews_heading", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xl={12}>
              <FormGroup>
                <RTE
                  data={tempForm.reviews}
                  height={"500px"}
                  formLabel={"Reviews"}
                  disabled={false}
                  onChange={(event, editor) =>
                    onChangeField("reviews", editor.getData() || "")
                  }
                />
              </FormGroup>
            </Col>
            <Col xl={12}>
              <Row>
                <Col xl={6}>
                  <FormGroup>
                    <Label for="pros_input">Pros</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        id="pros_input"
                        value={proItem}
                        onChange={(e) => setProItem(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setTempForm((prevForm) => ({
                              ...prevForm,
                              pros: [...prevForm.pros, proItem],
                            }));
                            setProItem("");
                          }}
                        >
                          Add
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <ListGroup>
                    {tempForm.pros.map((item, index) => (
                      <ListGroupItem key={index}>
                        {item}
                        <Button
                          close
                          onClick={() => {
                            setTempForm((prevForm) => ({
                              ...prevForm,
                              pros: prevForm.pros.filter((_, i) => i !== index),
                            }));
                          }}
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup>
                    <Label for="cons_input">Cons</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        id="cons_input"
                        value={conItem}
                        onChange={(e) => setConItem(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setTempForm((prevForm) => ({
                              ...prevForm,
                              cons: [...prevForm.cons, conItem],
                            }));
                            setConItem("");
                          }}
                        >
                          Add
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <ListGroup>
                    {tempForm.cons.map((item, index) => (
                      <ListGroupItem key={index}>
                        {item}
                        <Button
                          close
                          onClick={() => {
                            setTempForm((prevForm) => ({
                              ...prevForm,
                              cons: prevForm.cons.filter((_, i) => i !== index),
                            }));
                          }}
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            </Col>
            <Col xl={12} className="mt-3">
              <FormGroup>
                <Label>
                  Must Read Post
                  <AiFillEdit
                    style={{ cursor: "pointer" }}
                    className="text-primary ml-2"
                    onClick={() => setChoosePost(true)}
                  />
                </Label>
                <Input
                  value={tempSelectedPost?.title ?? "-"}
                  disabled={true}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col xl={12} className="mt-3">
              <FormGroup>
                <Label>Slug</Label>
                <Input value={tempForm["slug"]} disabled={true} readOnly />
              </FormGroup>
            </Col>
            <Col lg={12}>
              <ImageField
                imageData={images}
                onRemove={(url, index) => {
                  deleteImage(images[index].id, selectedModel.id);
                }}
                onAddFile={(val) => {
                  createImage({
                    url: val,
                    mywheels_car_brand_model_id: selectedModel.id,
                  });
                }}
              />
            </Col>
          </Row>
        </Form>
        <CustomModal
          size={"xl"}
          isOpen={showChoosePost}
          onClose={() => setChoosePost(false)}
        >
          <PostModal
            onSubmit={(val) => {
              setTempSelectedPost(val);
              onChangeField("must_read_post_id", val.id);
              setChoosePost(false);
            }}
            onClose={() => setChoosePost(false)}
          />
        </CustomModal>
      </ChinaPressModalBody>
      <ChinaPressModalFooter
        rightButton={[
          {
            color: "primary",
            disabled: tempForm.brand_id === null || !tempForm.title,
            content: "Submit",
            onClick: () => onSubmit(tempForm, selectedModel?.id),
          },
        ]}
      />
      {onLoadImage && <LoadingIndicator />}
    </>
  );
};

export default ImageHOC(ModelForm);
