import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			postCarModels: [],
		};

		onChangePostCarModelHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPostCarModel = (id) => {
			Get(
				`/mywheels/post/car-brand-models?post_id=${id}`,
				this.getPostCarModelSuccess,
				this.getPostCarModelError,
				this.load
			);
		};
		getPostCarModelSuccess = (payload) =>
			this.setState({ postCarModels: payload.data });
		getPostCarModelError = (error) => requestError(error);

		createPostCarModel = (dataToSubmit) =>
			Post(
				`/mywheels/post/car-brand-models`,
				dataToSubmit,
				this.createPostCarModelSuccess,
				this.createPostCarModelError,
				this.load
			);
		createPostCarModelSuccess = (payload) => {
			this.getPostCarModel(payload.data.post_id);
			requestSuccess("Categorys has been added successfully.");
		};
		createPostCarModelError = (error) => requestError(error);

		deletePostCarModel = (id, postID) =>
			Delete(
				`/mywheels/post/car-brand-models/${id}`,
				() => this.deletePostCarModelSuccess(postID),
				this.deletePostCarModelError,
				this.load
			);
		deletePostCarModelSuccess = (postID) => {
			this.setState({ showDeleteModal: false });
			this.getPostCarModel(postID);
			requestSuccess("Category has been deleted succesfully.");
		};
		deletePostCarModelError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCarModels={this.state.postCarModels}
					onLoadPostCarModel={this.state.loading}
					getPostCarModel={this.getPostCarModel}
					createPostCarModel={this.createPostCarModel}
					deletePostCarModel={this.deletePostCarModel}
					onChangePostCarModelHOC={this.onChangePostCarModelHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
