import Moment from "moment";

export const SampleVideoData = {
	platform: "myWheels",
	views: 0,
	start_at: `${Moment()}`,
	title: "",
	content: "",
	cover_img_with_title_url: "",
	cover_img_without_title_url: "",
	created_by: "",
	short_url: "",
	is_pin: false,
	status: "scheduled",
	type: "video",
	likes: 0,

	mywheels_tags: [],
	article_feature_img_url: "",
	slug: "",
	photographer_id: null,
	author_id: null,
	slug_category_id: "",
	video_embed_url: "",
	further_reading_post_id: null,
};
