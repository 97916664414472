import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			postCategory: [],
		};

		onChangePostCategoryHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPostCategory = (id) => {
			Get(
				`/mywheels/categories-posts?post_id=${id}`,
				this.getPostCategorySuccess,
				this.getPostCategoryError,
				this.load
			);
		};
		getPostCategorySuccess = (payload) =>
			this.setState({ postCategory: payload.data });
		getPostCategoryError = (error) => requestError(error);

		createPostCategory = (dataToSubmit) =>
			Post(
				`/mywheels/categories-posts`,
				dataToSubmit,
				this.createPostCategorySuccess,
				this.createPostCategoryError,
				this.load
			);
		createPostCategorySuccess = (payload) => {
			this.getPostCategory(payload.data.post_id);
			requestSuccess("Categorys has been added successfully.");
		};
		createPostCategoryError = (error) => requestError(error);

		deletePostCategory = (id, postID) =>
			Delete(
				`/mywheels/categories-posts/${id}`,
				() => this.deletePostCategorySuccess(postID),
				this.deletePostCategoryError,
				this.load
			);
		deletePostCategorySuccess = (postID) => {
			this.setState({ showDeleteModal: false });
			this.getPostCategory(postID);
			requestSuccess("Category has been deleted succesfully.");
		};
		deletePostCategoryError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCategory={this.state.postCategory}
					onLoadPostCategory={this.state.loading}
					getPostCategory={this.getPostCategory}
					createPostCategory={this.createPostCategory}
					deletePostCategory={this.deletePostCategory}
					onChangePostCategoryHOC={this.onChangePostCategoryHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
