import React, { useState, useEffect } from "react";
import _ from "lodash";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";
import { Input, FormGroup, Label } from "reactstrap";
import ImageField from "components/Input/File";
import DatePicker from "react-datepicker";
import Moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

export default function Form({ selectedBrand, onSubmit }) {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [scheduleDate, setScheduleDate] = useState();

  useEffect(() => {
    if (selectedBrand) {
      setName(selectedBrand?.name ?? "");
      setUrl(selectedBrand?.logo_url ?? "");
      setScheduleDate(selectedBrand?.start_at ?? null);
      setIntroduction(selectedBrand?.introduction ?? "");
    }
  }, [selectedBrand]);

  return (
    <>
      <ChinaPressModalBody>
        <FormGroup>
          <Label>Name</Label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Introduction</Label>
          <Input
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <ImageField
            storagePath="car_brand"
            label={"Brand Logo"}
            fileValue={url}
            onRemoveFile={() => setUrl("")}
            onSelectImage={(val) => setUrl(val)}
          />
        </FormGroup>
      </ChinaPressModalBody>
      <ChinaPressModalFooter
        rightButton={[
          {
            color: "primary",
            disabled: !name,
            content: "Save",
            onClick: () =>
              onSubmit(
                {
                  name,
                  logo_url: url,
                  introduction,
                  status: "live",
                },
                selectedBrand?.id
              ),
          },
        ]}
      />
    </>
  );
}
