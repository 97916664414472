import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Put, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			posts: [],
			postCounts: 0,
			pageIndex: 1,
			configCategoryName: null,
			configCategoryId: null,
			selectedDeleteConfigCategoryItems: null,
			selectedConfigCategoryItems: null,
			showEditConfigCategoryItemsModal: false,
			showCreateConfigCategoryItemsModal: false,
		};

		onChangeConfigCategoryItemsHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getConfigCategoryItems = (key) => {
			Get(
				`/mywheels/configcategoryitems/getbykey/${key}?${`offset=${(this.state.pageIndex - 1) * 10}&limit=10`}`,
				this.getConfigCategoryItemsSuccess,
				this.getConfigCategoryItemsError,
				this.load
			);
		};
		getConfigCategoryItemsSuccess = (payload) => {
			this.setState({
				configCategoryName: payload.data.categoryName,
				configCategoryId: payload.data.categoryId,
				posts: payload.data.rows,
				postCounts: payload.data.count,
			});
		};
		getConfigCategoryItemsError = (error) => requestError(error);

		getSelectedConfigCategoryItems = (val) => {
			this.getSelectedConfigCategoryItemsSuccess(val);
			this.load(false);
		};
		getSelectedConfigCategoryItemsSuccess = (payload) => {
			let tempApiCall = [];

			Promise.all(tempApiCall)
				.then((res) => {
					this.setState({
						selectedConfigCategoryItems: {
							...payload,
						},
						showEditConfigCategoryItemsModal: true,
					});
				})
				.catch((err) => {
					requestError(err);
				});
		};

		updateConfigCategoryItems = (dataToSubmit) => {
			Put(
				`/mywheels/configcategoryitems/${dataToSubmit.id}`,
				{
					name: dataToSubmit.name,
				},
				this.updateConfigCategoryItemsSuccess,
				this.updateConfigCategoryItemsError,
				this.load
			);
		};
		updateConfigCategoryItemsSuccess = () => {
			requestSuccess("Record has been updated successfully.");

			this.setState({
				selectedDeleteConfigCategoryItems: null,
			});
			this.setState({showEditConfigCategoryItemsModal: false})

			this.getConfigCategoryItems(this.props.match.params.key);
		};
		updateConfigCategoryItemsError = (error) => requestError(error);

		deleteConfigCategoryItems = (dataToDelete) => {
			Delete(
				`/mywheels/configcategoryitems/${dataToDelete.id}`,
				this.deleteConfigCategoryItemsSuccess,
				this.deleteConfigCategoryItemsError,
				this.load
			);
		};
		deleteConfigCategoryItemsSuccess = () => {
			requestSuccess("Record has been deleted successfully.");

			this.setState({
				selectedDeleteConfigCategoryItems: null,
			});
			this.setState({showEditConfigCategoryItemsModal: false})

			this.getConfigCategoryItems(this.props.match.params.key);
		};
		deleteConfigCategoryItemsError = (error) => requestError(error);

		createConfigCategoryItems = (
			dataToSubmit,
		) => {
			Post(
				`/mywheels/configcategoryitems`,
				{
					...dataToSubmit,
					configCategoryId: this.state.configCategoryId,
				},
				(payload) =>
					this.createConfigCategoryItemsSuccess(
						payload,
					),
				this.createConfigCategoryItemsError,
				this.load
			);
		};
		createConfigCategoryItemsSuccess = (
			payload,
		) => {
			requestSuccess("Record has been created successfully.");
			this.setState({ showCreateConfigCategoryItemsModal: false });

			this.getConfigCategoryItems(this.props.match.params.key);
		};
		createConfigCategoryItemsError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCounts={this.state.postCounts}
					pageIndex={this.state.pageIndex}
					selectedDeleteConfigCategoryItems={this.state.selectedDeleteConfigCategoryItems}
					posts={this.state.posts}
					searchParams={this.state.searchParams}
					showCreateConfigCategoryItemsModal={this.state.showCreateConfigCategoryItemsModal}
					showEditConfigCategoryItemsModal={this.state.showEditConfigCategoryItemsModal}
					selectedConfigCategoryItems={this.state.selectedConfigCategoryItems}
					onLoadConfigCategoryItems={this.state.loading}
					configCategoryName={this.state.configCategoryName}
					configCategoryId={this.state.configCategoryId}
					getConfigCategoryItems={this.getConfigCategoryItems}
					getConfigCategoryName={this.getConfigCategoryName}
					updateConfigCategoryItems={this.updateConfigCategoryItems}
					createConfigCategoryItems={this.createConfigCategoryItems}
					deleteConfigCategoryItems={this.deleteConfigCategoryItems}
					onChangeConfigCategoryItemsHOC={this.onChangeConfigCategoryItemsHOC}
					getSelectedConfigCategoryItems={this.getSelectedConfigCategoryItems}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
