import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			flattenCategories: [],
		};

		onChangeSampleHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		handleGetCategoryPromise = (index) => {
			return new Promise((resolve, reject) => {
				Get(
					`/mywheels/find-children-categories/${index + 1}`,
					(payload) => {
						resolve(payload.data);
					},
					(error) => {
						reject(error);
					},
					this.load
				);
			});
		};

		getCategories = () => {
			const apiPromises = [];
			for (let index = 0; index < 4; index++) {
				apiPromises.push(this.handleGetCategoryPromise(index));
			}

			Promise.all(apiPromises)
				.then((responses) => {
					const tempFlatten = responses.flat();
					let tempFiltered = _.filter(tempFlatten, (val) => val.parent_id);

					this.setState({
						flattenCategories: tempFiltered,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					onLoadCategory={this.state.loading}
					getCategories={this.getCategories}
					flattenCategories={this.state.flattenCategories}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
