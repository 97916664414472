import React, { useState } from "react";
import { FormGroup } from "reactstrap";

import ImageField from "components/Input/File";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";

function AddImgModal({ onSubmit }) {
  const [tempUrl, setTempUrl] = useState("");

  return (
    <>
      <ChinaPressModalBody>
        <FormGroup>
          <ImageField
            storagePath="posts"
            label={""}
            fileValue={tempUrl}
            multiple={true}
            onRemoveFile={() => setTempUrl("")}
            onSelectImage={(val) => setTempUrl(val)}
          />
        </FormGroup>
      </ChinaPressModalBody>
      <ChinaPressModalFooter
        rightButton={[
          {
            color: "primary",
            disabled: !tempUrl,
            content: "Submit",
            onClick: () => onSubmit(tempUrl),
          },
        ]}
      />
    </>
  );
}

export default AddImgModal;
