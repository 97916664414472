import Moment from "moment";

export const SamplePostData = {
  platform: "myWheels",
  views: 0,
  start_at: `${Moment()}`,
  title: "",
  introduction: "",
  content: "",
  cover_img_with_title_url: "",
  cover_img_caption: "",
  cover_img_without_title_url: "",
  created_by: "",
  short_url: "",
  is_pin: false,
  status: "scheduled",
  type: "post",
  likes: 0,

  mywheels_tags: [],
  article_feature_img_url: "",
  slug: "",
  photographer_id: null,
  author_id: null,
  slug_category_id: "",
  slug_field: "",
  further_reading_post_id: null,
};

export const RootCategories = [
  {
    name: "试驾",
    id: 2,
    children: [],
  },
  {
    name: "两轮天地",
    id: 3,
    children: [],
  },
  {
    name: "综合",
    id: 4,
    children: [],
  },
  {
    name: "EV",
    id: 1,
    children: [],
  },
  {
    name: "新车",
    id: 5,
    children: [],
  },
];
