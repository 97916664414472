import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Put, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			posts: [],
			postCounts: 0,
			pageIndex: 1,
			selectedDeleteConfigCategory: null,
			selectedConfigCategory: null,
			showEditConfigCategoryModal: false,
			showCreateConfigCategoryModal: false,
		};

		onChangeConfigCategoryHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getConfigCategory = (val) => {
			Get(
				`/mywheels/configcategory?${`offset=${(this.state.pageIndex - 1) * 10}&limit=10`}`,
				this.getConfigCategorySuccess,
				this.getConfigCategoryError,
				this.load
			);
		};
		getConfigCategorySuccess = (payload) => {
			this.setState({
				posts: payload.data.rows,
				postCounts: payload.data.count,
			});
		};
		getConfigCategoryError = (error) => requestError(error);

		getSelectedConfigCategory = (val) => {
			this.getSelectedConfigCategorySuccess(val);
			this.load(false);
		};
		getSelectedConfigCategorySuccess = (payload) => {
			let tempApiCall = [];

			Promise.all(tempApiCall)
				.then((res) => {
					this.setState({
						selectedConfigCategory: {
							...payload,
						},
						showEditConfigCategoryModal: true,
					});
				})
				.catch((err) => {
					requestError(err);
				});
		};

		updateConfigCategory = (dataToSubmit) => {
			Put(
				`/mywheels/configcategory/${dataToSubmit.id}`,
				{
					name: dataToSubmit.name,
				},
				this.updateConfigCategorySuccess,
				this.updateConfigCategoryError,
				this.load
			);
		};
		updateConfigCategorySuccess = () => {
			requestSuccess("Record has been updated successfully.");

			this.setState({
				selectedDeleteConfigCategory: null,
			});
			this.setState({showEditConfigCategoryModal: false})

			this.getConfigCategory();
		};
		updateConfigCategoryError = (error) => requestError(error);

		deleteConfigCategory = (dataToDelete) => {
			Delete(
				`/mywheels/configcategory/${dataToDelete.id}`,
				this.deleteConfigCategorySuccess,
				this.deleteConfigCategoryError,
				this.load
			);
		};
		deleteConfigCategorySuccess = () => {
			requestSuccess("Record has been deleted successfully.");

			this.setState({
				selectedDeleteConfigCategory: null,
			});
			this.setState({showEditConfigCategoryModal: false})

			this.getConfigCategory();
		};
		deleteConfigCategoryError = (error) => requestError(error);

		createConfigCategory = (
			dataToSubmit,
		) => {
			Post(
				`/mywheels/configcategory`,
				{
					...dataToSubmit,
				},
				(payload) =>
					this.createConfigCategorySuccess(
						payload,
					),
				this.createConfigCategoryError,
				this.load
			);
		};
		createConfigCategorySuccess = (
			payload,
		) => {
			requestSuccess("Record has been created successfully.");
			this.setState({ showCreateConfigCategoryModal: false });

			this.getConfigCategory();
		};
		createConfigCategoryError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCounts={this.state.postCounts}
					pageIndex={this.state.pageIndex}
					selectedDeleteConfigCategory={this.state.selectedDeleteConfigCategory}
					posts={this.state.posts}
					searchParams={this.state.searchParams}
					showCreateConfigCategoryModal={this.state.showCreateConfigCategoryModal}
					showEditConfigCategoryModal={this.state.showEditConfigCategoryModal}
					selectedConfigCategory={this.state.selectedConfigCategory}
					onLoadConfigCategory={this.state.loading}
					getConfigCategory={this.getConfigCategory}
					updateConfigCategory={this.updateConfigCategory}
					createConfigCategory={this.createConfigCategory}
					deleteConfigCategory={this.deleteConfigCategory}
					onChangeConfigCategoryHOC={this.onChangeConfigCategoryHOC}
					getSelectedConfigCategory={this.getSelectedConfigCategory}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
