import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import CreateModelForm from "./Create.js";
import EditModelForm from "./Edit.js";
import SearchForm from "components/SearchForm";
import { queryHandler } from "utils/queryHandler";

import ModelHOC from "./actions/index.js";

class Model extends Component {
  componentDidMount = () => {
    let tempQeuery = queryHandler(this.props.searchParams);
    this.props.getModel(tempQeuery);
    this.props.getBrand();
    window.addEventListener("keydown", this.handleKeyDown);
  };

  componentDidUpdate = (pp) => {
    if (pp.brands !== this.props.brands && this.props.brands?.[0]) {
      let tempIndex = _.findIndex(
        this.props.searchParams,
        (val) => val.value === "mywheels_car_brand_id"
      );
      if (tempIndex > -1) {
        let tempSearchParams = _.cloneDeep(this.props.searchParams);
        tempSearchParams[tempIndex]["options"] = this.props.brands;

        this.props.onChangeModelHOC("searchParams", tempSearchParams);
      }
    }

    if (pp.pageIndex !== this.props.pageIndex) {
      let tempQeuery = queryHandler(this.props.searchParams);
      this.props.getVariant(tempQeuery);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !this.props.showCreateModelModal &&
      !this.props.showEditModelModal
    ) {
      let tempQeuery = queryHandler(this.props.searchParams);
      this.props.getModel(tempQeuery);
    }
  };

  renderCreateModel = () => {
    return (
      <ChinaPressModal
        size={"xl"}
        isOpen={this.props.showCreateModelModal}
        onClose={() =>
          this.props.onChangeModelHOC("showCreateModelModal", false)
        }
      >
        <CreateModelForm
          brands={this.props.brands}
          onSubmit={this.props.createModel}
        />
      </ChinaPressModal>
    );
  };

  renderEditModel = () => {
    return (
      <ChinaPressModal
        size={"xl"}
        isOpen={this.props.showEditModelModal}
        onClose={() => {
          this.props.onChangeModelHOC("selectedModel", null);
          this.props.onChangeModelHOC("showEditModelModal", false);
        }}
      >
        <EditModelForm
          brands={this.props.brands}
          selectedModel={this.props.selectedModel}
          onSubmit={this.props.updateModel}
        />
      </ChinaPressModal>
    );
  };

  renderModelTable = () => {
    return (
      <MyWheelsTable
        data={this.props.models.rows}
        columnsContent={[
          {
            Header: "Model Title",
            accessorKey: "title",
            centerColumn: true,
            Cell: (val) => <>{val?.title}</>,
          },
          {
            Header: "Brand",
            accessorKey: "",
            centerColumn: true,
            Cell: (val) => <>{val?.mywheels_car_brands?.name ?? ""}</>,
          },
          {
            Header: "Status",
            accessorKey: "status",
            centerColumn: true,
            Cell: (val) => (
              <>
                <span
                  className={`badge ${
                    val.status === "active"
                      ? "badge-success"
                      : "badge-secondary"
                  }`}
                >
                  {val.status}
                </span>
              </>
            ),
          },
        ]}
        actionsContent={[
          {
            content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
            actionID: "EditPost",
            color: "primary",
            tooltipContent: "Edit",
            onClick: (val) => {
              this.props.onChangeModelHOC("selectedModel", val);
              this.props.onChangeModelHOC("showEditModelModal", true);
            },
          },
        ]}
      />
    );
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Car Models"
          subheading="Listings of all the car models present in the system."
          icon="pe-7s-car icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: "primary",
              className: "btn-icon mr-2",
              onClick: () => {
                this.props.onChangeModelHOC("showCreateModelModal", true);
              },
              content: (
                <>
                  <span>Create Model</span>
                </>
              ),
            },
          ]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Row>
            <Col>
              <SearchForm
                searchParams={this.props.searchParams}
                onChangeHOCState={this.props.onChangeModelHOC}
                getListAPI={this.props.getModel}
              />
            </Col>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>{this.renderModelTable()}</CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        {this.renderCreateModel()}
        {this.renderEditModel()}
        {this.props.onLoadModel && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(ModelHOC)(Model);
