import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";
import { Link } from 'react-router-dom';

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import CreateConfigCategorysForm from "./Create/General";
import EditConfigCategoryform from "./Edit/General";
import Pagination from "components/Pagination";
import CustomConfirmationForm from "components/Modal/confirmation.js";

import ConfigCategoryHOC from "./actions/index";

class ConfigCategory extends Component {
	componentDidMount = () => {
		this.props.getConfigCategory();
	};

	componentDidUpdate = (pp) => {
		if (pp.pageIndex !== this.props.pageIndex) {
			this.props.getConfigCategory();
		}
	};

	isTwitterWidgetScriptLoaded() {
		const scripts = document.getElementsByTagName("script");
		for (let i = 0; i < scripts.length; i++) {
			if (scripts[i].src === "https://platform.twitter.com/widgets.js") {
				return true;
			}
		}
		return false;
	}

	renderCreateConfigCategory = () => {
		return (
			<ChinaPressModal
				size="md"
				isOpen={this.props.showCreateConfigCategoryModal}
				title="New Category"
				onClose={() =>
					this.props.onChangeConfigCategoryHOC("showCreateConfigCategoryModal", false)
				}>
				<CreateConfigCategorysForm
					onSubmit={this.props.createConfigCategory}
				/>
				{this.props.onLoadConfigCategory && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderEditConfigCategory = () => {
		return (
			<ChinaPressModal
				size="md"
				isOpen={this.props.showEditConfigCategoryModal}
				title="Edit Category"
				onClose={() => {
					this.props.onChangeConfigCategoryHOC("showEditConfigCategoryModal", false);
					this.props.onChangeConfigCategoryHOC("selectedConfigCategory", null);
					this.props.onChangeConfigCategoryHOC("selectedConfigCategoryTags", []);
				}}>
				<EditConfigCategoryform
					selectedConfigCategory={this.props.selectedConfigCategory}
					onSubmit={this.props.updateConfigCategory}
				/>
				{this.props.onLoadConfigCategory && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderConfigCategoryTable = () => {
		return (
			<MyWheelsTable
				showPagination={false}
				data={this.props.posts}
				columnsContent={[
					{
						Header: "Name",
						accessorKey: "name",
						centerColumn: true,
						Cell: (val) => <>{<Link to={`/dashboard/configcategoryitems/${val.key}`}>{val.name}</Link> || "N/A"}</>,
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditConfigCategory",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.getSelectedConfigCategory(val);
						},
					},
					{
						content: <i className="pe-7s-trash btn-icon-wrapper"> </i>,
						actionID: "DeleteConfigCategory",
						color: "danger",
						tooltipContent: "Delete Category",
						onClick: (val) => {
							this.props.onChangeConfigCategoryHOC("selectedDeleteConfigCategory", val);
						},
					},
					{
						content: <i className="pe-7s-look btn-icon-wrapper"> </i>,
						actionID: "ViewConfigCategory",
						color: "primary",
						tooltipContent: "View Category Items",
						onClick: (val) => {
							const { history } = this.props;
							history.push(`/dashboard/configcategoryitems/${val.key}`)
						}
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading="Category"
					subheading="Listings of all the category."
					icon="pe-7s-menu icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeConfigCategoryHOC("showCreateConfigCategoryModal", true);
							},
							content: (
								<>
									<span>Create Category</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<Card className="main-card mb-3">
								<CardBody>
									{this.renderConfigCategoryTable()}
									<Pagination
										pageIndex={this.props.pageIndex}
										totalCount={this.props.postCounts}
										onChangePage={(val) =>
											this.props.onChangeConfigCategoryHOC("pageIndex", val)
										}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreateConfigCategory()}
				{this.renderEditConfigCategory()}
				<CustomConfirmationForm
					open={this.props.selectedDeleteConfigCategory !== null}
					title={"Remove category"}
					loading={this.props.onLoadConfigCategory}
					message={"Are you sure to move the selected category to the bin?"}
					onClose={() => this.props.onChangeConfigCategoryHOC("selectedDeleteConfigCategory", null)}
					onClickConfirm={() => {
						this.props.deleteConfigCategory({
							...this.props.selectedDeleteConfigCategory
						});
					}}
				/>
				{(this.props.onLoadConfigCategory ||
					this.props.onLoadCategory ||
					this.props.onLoadTags) && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(ConfigCategoryHOC)(ConfigCategory);
