import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";

function VideoModal({ onSubmit, selectedVideo, postID }) {
	const [url, setUrl] = useState("");

	useEffect(() => {
		selectedVideo && setUrl(selectedVideo.url);
	}, [selectedVideo]);

	return (
		<>
			<CustomModalBody>
				<Form>
					<FormGroup>
						<Label>Video URL</Label>
						<Input value={url} onChange={(e) => setUrl(e.target.value)} />
					</FormGroup>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						content: "Submit",
						disabled: !url,
						color: "primary",
						onClick: () => {
							onSubmit({
								url,
								post_id: postID,
							}, selectedVideo?.id );
						},
					},
				]}
			/>
		</>
	);
}

export default VideoModal;
