import { useRef, useState } from "react";
import { Button, Label, UncontrolledTooltip } from "reactstrap";

import { AiFillDelete } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import Loader from "react-loaders";

import MediaDB from "components/Modal/MediaDB";
import HOC from "./action";
import "./index.scss";

const InputFile = ({
  label,
  storagePath,
  onRemoveFile,
  multiple,
  fileValue,
  onLoadAsset,
  onSelectImage,
  createAssets,
  onChangeAssetHOC,
}) => {
  const [showMediaDB, setShowMediaDB] = useState(false);
  let fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  let onDropFile = (event) => {
    let tempPlatforms = [];
    if (window.location.hash.includes("job-macha")) {
      tempPlatforms.push("jobmacha");
    } else if (window.location.hash.includes("service-macha")) {
      tempPlatforms.push("servicemacha");
    } else {
      tempPlatforms.push("myWheels");
    }

    if (event.target.files.length > 0) {
      if (!multiple) {
        let reader = new FileReader();
        reader.onload = (e) => {
          createAssets(
            {
              storagePath: storagePath,
              base64: e.target.result,
              filename: event.target.files[0].name,
              alt: event.target.files[0].name,
              title: event.target.files[0].name,
              platforms: tempPlatforms,
            },
            (val) => {
              onSelectImage(val);
            }
          );
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        let tempUrl = [];
        onChangeAssetHOC("loading", true);
        Array.from(event.target.files).forEach((file) => {
          let reader = new FileReader();
          reader.onload = (e) => {
            createAssets(
              {
                storagePath: storagePath,
                base64: e.target.result,
                filename: file.name,
                alt: file.name,
                title: file.name,
                platforms: tempPlatforms,
              },
              (val) => tempUrl.push(val)
            );
          };
          reader.readAsDataURL(file);
        });
        onSelectImage(tempUrl);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      onDropFile({ target: { files } });
    }
  };

  return (
    <>
      <Label className="d-flex align-items-end">
        {label}
        {!onLoadAsset && (
          <div className="ml-auto">
            <Button
              color="primary"
              id={"mywheels-append-img"}
              onClick={() => {
                setShowMediaDB(true);
              }}
            >
              <BsFillImageFill />
              <UncontrolledTooltip target="mywheels-append-img">
                Use existing image from Drive
              </UncontrolledTooltip>
            </Button>
          </div>
        )}
      </Label>
      {!onLoadAsset && (
        <div className="mana_file-input">
          <div
            className={`mana_file-input-empty-div ${fileValue.length < 1 ? "" : "d-none"
              }`}
            onClick={() => fileInputRef.current.click()}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: isDragging ? "2px dashed #00f" : "2px dashed #aaa",
              cursor: "pointer",
              backgroundColor: isDragging ? "#f0f8ff" : "#fff",
            }}
          >
            <input
              ref={fileInputRef}
              type={"file"}
              multiple={multiple || false}
              onChange={(event) => onDropFile(event)}
              style={{ display: "none" }}
              accept={".jpg,.jpeg,.png"}
            />
            <BsFillImageFill className="mana_file-input-bg-svg" />
            <span className="mana_file-input-help-text">
              Drop and Upload your image here.
            </span>
          </div>
          {typeof fileValue === "object" &&
            fileValue.length > 0 &&
            fileValue.map((fileChild, fileIndex) => (
              <div
                className={`mana_file-input-filled-div mb-3 ${fileChild ? "" : "d-none"
                  }`}
              >
                <img className="mana_file-input-img" src={fileChild} alt="" />
                <AiFillDelete
                  id={"RemoveFile"}
                  className={"mana_file-input-remove-btn"}
                  onClick={() => {
                    onRemoveFile(fileIndex);
                    fileInputRef.current.value = "";
                  }}
                />
                <UncontrolledTooltip target={`RemoveFile`} placement="top">
                  Click to remove the file here.
                </UncontrolledTooltip>
              </div>
            ))}
          {typeof fileValue === "string" && fileValue && (
            <div
              className={`mana_file-input-filled-div ${fileValue ? "" : "d-none"
                }`}
            >
              <img className="mana_file-input-img" src={fileValue} alt="" />
              <AiFillDelete
                id={"RemoveFile"}
                className={"mana_file-input-remove-btn"}
                onClick={() => {
                  onRemoveFile();
                  fileInputRef.current.value = "";
                }}
              />
              <UncontrolledTooltip target={`RemoveFile`} placement="top">
                Click to remove the file here.
              </UncontrolledTooltip>
            </div>
          )}
        </div>
      )}
      {onLoadAsset && (
        <div style={{ marginTop: 35, marginBottom: 30 }}>
          <Loader type="ball-pulse-rise" />
        </div>
      )}
      <MediaDB
        isOpen={showMediaDB}
        multiSelect={multiple}
        onClickView={() => { }}
        onSelect={(val) => {
          onSelectImage(val);
          setShowMediaDB(false);
        }}
        onClose={() => setShowMediaDB(false)}
      />
    </>
  );
};

export default HOC(InputFile);
