import React, { useEffect, Fragment } from "react";
import _ from "lodash";
import {
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Row,
} from "reactstrap";
import PostCategoryHOC from "../actions/postCategory";

function Categories({
	selectedPost,
	postCategory,
	categories,
	getPostCategory,
	createPostCategory,
	deletePostCategory,
}) {
	useEffect(() => {
		getPostCategory(selectedPost.id);
	}, []);
	return (
		<Card>
			<CardBody>
				<Label className="mb-2">Post Categories</Label>
				<hr />
				<Form>
					<Row>
						{categories.map((categoryChild) => (
							<Col lg={3} md={4} sm={6} key={`category_${categoryChild.id}`}>
								<FormGroup>
									<FormGroup style={{ marginLeft: "1.25rem" }}>
										<Input
											type={"checkbox"}
											color="primary"
											checked={
												_.findIndex(postCategory, {
													mywheels_category_id: categoryChild.id,
												}) > -1
											}
											onChange={() => {
												let tempIndex = _.findIndex(postCategory, (child) => {
													return (
														child.mywheels_category_id === categoryChild.id
													);
												});

												tempIndex < 0
													? createPostCategory({
															post_id: selectedPost.id,
															mywheels_category_id: categoryChild.id,
													  })
													: postCategory[tempIndex]?.id &&
													  deletePostCategory(
															postCategory[tempIndex].id,
															postCategory[tempIndex].post_id
													  );
											}}
										/>
										<Label>{categoryChild.name}</Label>
									</FormGroup>
								</FormGroup>
							</Col>
						))}
					</Row>
				</Form>
			</CardBody>
		</Card>
	);
}

export default PostCategoryHOC(Categories);
