import React, { Component } from "react";
import { connect } from "react-redux";

import { Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
		};

		onChangeAssetHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		createAssets = (dataToSubmit, callback) =>
			Post(
				`/assets`,
				{
					...dataToSubmit,
					platforms: [this.props.data.ProfileReducer.profile.platform],
				},
				(val) => this.createAssetsSuccess(val, callback),
				this.createAssetsError,
				this.load
			);
		createAssetsSuccess = (payload, callback) => {
			callback(payload.data.url);
			this.setState({ showCreateAssetsModal: false });
			requestSuccess("Record has been created successfully.");
		};
		createAssetsError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					createAssets={this.createAssets}
					onChangeAssetHOC={this.onChangeAssetHOC}
					onLoadAsset={this.state.loading}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
