import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			selectedVideoTags: [],
			tags: [],
		};

		onChangeTagsHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getTag = () =>
			Get(`/mywheels/tags`, this.getTagSuccess, this.getTagError, this.load);
		getTagSuccess = (payload) => {
			let temp = _.map(payload.data, (tempChild) => ({
				value: tempChild.id,
				label: tempChild.name,
				...tempChild,
			}));

			this.setState({
				tags: temp,
			});
		};
		getTagError = (error) => requestError(error);

		createTag = (dataToSubmit, cb) => {
			Post(
				`/mywheels/tags`,
				dataToSubmit,
				(payload) => this.createTagSuccess(payload, cb),
				this.createTagError,
				this.load
			);
		};
		createTagSuccess = (payload, cb) => {
			this.getTag();
			cb({
				...payload.data,
				value: payload.data.id,
				label: payload.data.name,
			});
			requestSuccess("Record created successfully.");
		};
		createTagError = (error) => requestError(error);

		getVideoTags = (id) =>
			Get(
				`/mywheels/tags-posts?post_id=${id}`,
				this.getVideoTagsSuccess,
				this.getVideoTagsError,
				this.load
			);
		getVideoTagsSuccess = (payload) => {
			this.setState({
				selectedVideoTags: payload.data,
			});
		};
		getVideoTagsError = (error) => requestError(error);

		addVideoTag = (postId, tagID) =>
			Post(
				`/mywheels/tags-posts`,
				{
					mywheels_tag_id: tagID,
					post_id: postId,
				},
				() => this.addVideoTagSuccess(postId),
				this.addVideoTagError,
				this.load
			);
		addVideoTagSuccess = (postId) => {
			this.getVideoTags(postId);
			requestSuccess("Record created successfully.");
		};
		addVideoTagError = (error) => requestError(error);

		deleteVideoTag = (id, postId) =>
			Delete(
				`/mywheels/tags-posts/${id}`,
				() => this.deleteVideoTagSuccess(postId),
				this.deleteVideoTagError,
				this.load
			);
		deleteVideoTagSuccess = (postId) => {
			this.getVideoTags(postId);
			requestSuccess("Record deleted successfully.");
		};
		deleteVideoTagError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					selectedVideoTags={this.state.selectedVideoTags}
					tags={this.state.tags}
					onLoadTags={this.state.loading}
					getTag={this.getTag}
					getSelectedTag={this.getSelectedTag}
					createTag={this.createTag}
					getVideoTags={this.getVideoTags}
					deleteVideoTag={this.deleteVideoTag}
					addVideoTag={this.addVideoTag}
					onChangeTagsHOC={this.onChangeTagsHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
