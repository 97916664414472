import React, { useState, useEffect } from "react";
import _ from "lodash";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";
import { Input, FormGroup, Label } from "reactstrap";

export default function Form({ selectedTag, onSubmit }) {
	const [name, setName] = useState("");

	useEffect(() => {
		if (selectedTag) {
			setName(selectedTag.name ?? "");
		}
	}, [selectedTag]);

	return (
		<>
			<ChinaPressModalBody>
				<FormGroup>
					<Label>Name</Label>
					<Input value={name} onChange={(e) => setName(e.target.value)} />
				</FormGroup>
			</ChinaPressModalBody>
			<ChinaPressModalFooter
				rightButton={[
					{
						color: "primary",
						disabled: !name,
						content: "Submit",
						onClick: () => {
							if (selectedTag) {
								return onSubmit({ name, id: selectedTag.id });
							}
							onSubmit({ name });
						},
					},
				]}
			/>
		</>
	);
}
