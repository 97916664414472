import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";
import { withRouter } from "react-router-dom";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import CreateConfigCategoryItemsForm from "./Create/General";
import EditConfigCategoryItemsform from "./Edit/General";
import Pagination from "components/Pagination";
import CustomConfirmationForm from "components/Modal/confirmation.js";

import ConfigCategoryItemsHOC from "./actions/index";

class ConfigCategoryItems extends Component {
	componentDidMount = () => {
		// getting 'key' from url - 'withRouter'
		const { key } = this.props.match.params;
		this.props.getConfigCategoryItems(key);
	};

	componentDidUpdate = (pp) => {
		const { key } = this.props.match.params;
		if (pp.pageIndex !== this.props.pageIndex) {
			this.props.getConfigCategoryItems(key);
		}
	};

	isTwitterWidgetScriptLoaded() {
		const scripts = document.getElementsByTagName("script");
		for (let i = 0; i < scripts.length; i++) {
			if (scripts[i].src === "https://platform.twitter.com/widgets.js") {
				return true;
			}
		}
		return false;
	}

	renderCreateConfigCategoryItems = () => {
		return (
			<ChinaPressModal
				size="md"
				title="New Item"
				isOpen={this.props.showCreateConfigCategoryItemsModal}
				onClose={() =>
					this.props.onChangeConfigCategoryItemsHOC("showCreateConfigCategoryItemsModal", false)
				}>
				<CreateConfigCategoryItemsForm
					onSubmit={this.props.createConfigCategoryItems}
				/>
				{this.props.onLoadConfigCategoryItems && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderEditConfigCategoryItems = () => {
		return (
			<ChinaPressModal
				size="md"
				title="Edit Item"
				isOpen={this.props.showEditConfigCategoryItemsModal}
				onClose={() => {
					this.props.onChangeConfigCategoryItemsHOC("showEditConfigCategoryItemsModal", false);
					this.props.onChangeConfigCategoryItemsHOC("selectedConfigCategoryItems", null);
					this.props.onChangeConfigCategoryItemsHOC("selectedConfigCategoryItemsTags", []);
				}}>
				<EditConfigCategoryItemsform
					selectedConfigCategoryItems={this.props.selectedConfigCategoryItems}
					onSubmit={this.props.updateConfigCategoryItems}
				/>
				{this.props.onLoadConfigCategoryItems && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderConfigCategoryItemsTable = () => {
		return (
			<MyWheelsTable
				showPagination={false}
				data={this.props.posts}
				columnsContent={[
					{
						Header: "Name",
						accessorKey: "name",
						centerColumn: true,
						Cell: (val) => <>{val.name || "N/A"}</>,
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditConfigCategoryItems",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.getSelectedConfigCategoryItems(val);
						},
					},
					{
						content: <i className="pe-7s-trash btn-icon-wrapper"> </i>,
						actionID: "DeleteConfigCategoryItems",
						color: "danger",
						tooltipContent: "Delete Item",
						onClick: (val) => {
							this.props.onChangeConfigCategoryItemsHOC("selectedDeleteConfigCategoryItems", val);
						},
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading={`${this.props.configCategoryName}`}
					subheading="Listings of category items."
					icon="pe-7s-menu icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeConfigCategoryItemsHOC("showCreateConfigCategoryItemsModal", true);
							},
							content: (
								<>
									<span>Create Item</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<Card className="main-card mb-3">
								<CardBody>
									{this.renderConfigCategoryItemsTable()}
									<Pagination
										pageIndex={this.props.pageIndex}
										totalCount={this.props.postCounts}
										onChangePage={(val) =>
											this.props.onChangeConfigCategoryItemsHOC("pageIndex", val)
										}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreateConfigCategoryItems()}
				{this.renderEditConfigCategoryItems()}
				<CustomConfirmationForm
					open={this.props.selectedDeleteConfigCategoryItems !== null}
					title={"Remove category"}
					loading={this.props.onLoadConfigCategoryItems}
					message={"Are you sure to move the selected item to the bin?"}
					onClose={() => this.props.onChangeConfigCategoryItemsHOC("selectedDeleteConfigCategoryItems", null)}
					onClickConfirm={() => {
						this.props.deleteConfigCategoryItems({
							...this.props.selectedDeleteConfigCategoryItems
						});
					}}
				/>
				{(this.props.onLoadConfigCategoryItems ||
					this.props.onLoadCategory ||
					this.props.onLoadTags) && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(withRouter, ConfigCategoryItemsHOC)(ConfigCategoryItems);
