import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			images: [],
		};

		load = (param) => this.setState({ loading: param });

		getImage = (id) => {
			Get(
				`/post-galleries?post_id=${ id }`,
				this.getImageSuccess,
				this.getImageError,
				this.load
			);
		};
		getImageSuccess = (payload) => this.setState({ images: payload.data });
		getImageError = (error) => requestError(error);

		createImage = (dataToSubmit) =>
			Post(
				`/post-galleries`,
				dataToSubmit,
				this.createImageSuccess,
				this.createImageError,
				this.load
			);
		createImageSuccess = (payload) => {
			this.getImage( payload.data.mywheels_car_brand_model_id );
			requestSuccess("Image has been added successfully.");
		};
		createImageError = (error) => requestError(error);

		deleteImage = (id, postID) =>
			Delete(
				`/post-galleries/${id}`,
			 	() => this.deleteImageSuccess( postID ),
				this.deleteImageError,
				this.load
			);
		deleteImageSuccess = (postID) => {
			this.setState({ showDeleteModal: false });
			this.getImage( postID );
			requestSuccess("Image has been deleted succesfully.");
		};
		deleteImageError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					images={this.state.images}
					onLoadImage={this.state.loading}
					getImage={this.getImage}
					createImage={this.createImage}
					deleteImage={this.deleteImage}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
