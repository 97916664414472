// ***************************************** 
// TODO: TEMPORARY until config category is done in BE
export const Type = [
	"Hybrid",
	"SUV",
	"MPV",
	"Sedan",
	"Coupe",
	"Compact",
	"Wagon",
	"Van",
	"Hatchback",
	"Pick-up",
	"Sport Coupe",
	"Convertible",
	"BEV",
	"B-Segment SUV",
	"Commercial",
	"A-Segment SUV",
];

export const Transmission = [
	"Auto",
	"Manual",
	"Hybrid",
	"Semi-manual",
	"CVT",
	"e-CVT",
	"8-speed-at",
	"DCT",
	"6-Speed AT",
	"7 speed ISR",
	"4 -speed",
	"7-Speed DCT",
	"4 -speed AT",
	"5 Speed Manual",
	"Auto/Manual",
	"9-Speed AT",
	"7-speed CVT",
	"5 speed-MT",
	"6 speed MT",
	"5-speed AT",
	"7-Speed AT",
	"10-Speed AT",
	"Automatic single-speed reduction gear",
	"8-Speed DCT",
	"6-Speed DCT",
	"8-Speed tiptronic",
	"8-Speed DVT",
	"10 Speed CVT",
	"7-Speed DSG",
];

export const Engine = [
	"1.0cc",
	"1.5cc",
	"1.8cc",
	"2.0cc",
	"2.5cc",
	"5.0cc",
	"Turbo",
	"2.4cc",
	"3.0cc",
	"1.3CC",
	"780CV",
	"1.6 cc",
	"3.5 cc",
	"2.2CC",
	"1.4T 150PS",
	"4.0",
	"2.7cc",
	"2.8cc",
	"2.9cc",
	"4.7CC",
	"1.2CC",
	"3.9CC",
	"6.0",
	"3.8CC",
	"3.4cc",
	"5.7CC",
	"3.7CC",
	"5.9",
	"5.2",
];
// ***************************************** 

export const SampleData = {
	mywheels_car_brand_id: null,
	mywheels_car_brand_model_id: null,
	title: "",
	description: "",
	status: "active",
	engine: null,
	transmission: null,
	body_type: null,
	price: null,
	engine_capacity: null,
	engine_cylinder: null,
	horsepower: null,
	peak_torque: null,
	direct_injection: null,
	aspiration: null,
	fuel_type: null,
	battery: null,
	fuel_consumption: null,
	brake_system: null,
	length: null,
	width: null,
	height: null,
	kerb_weight: null,
	fuel_tank: null,
	market_price: null,
	front_tread: null,
	rear_tread: null,
	tyres: null,
	tyres_rim: null,
	front_suspension: null,
	rear_suspension: null,
	exterior_colour: null,
	warranty: null,
	equipment_highlight: null,
	front_airbag: null,
	rear_airbag: null,
	seat: null,
	fuel_economy: null,
};

export const variantFormData = [
	{
		label: "Engine",
		key: "engine",
		type: "select",
		options: Engine,
	},
	{
		label: "Transmission System",
		key: "transmission",
		type: "select",
		options: Transmission,
	},
	{
		label: "Body Type",
		key: "body_type",
		type: "select",
		options: Type,
	},
	{
		label: "Price",
		key: "price",
		type: "number",
	},
	{
		label: "Engine Capacity",
		key: "engine_capacity",
		type: "text",
	},
	{
		label: "Engine Cylinder",
		key: "engine_cylinder",
		type: "number",
	},
	{
		label: "Horsepower",
		key: "horsepower",
		type: "number",
	},
	{
		label: "Peak Torque",
		key: "peak_torque",
		type: "number",
	},
	{
		label: "Direct Injection",
		key: "direct_injection",
		type: "text",
	},
	{
		label: "Aspiration",
		key: "aspiration",
		type: "text",
	},
	{
		label: "Battery",
		key: "battery",
		type: "text",
	},
	{
		label: "Fuel Type",
		key: "fuel_type",
		type: "text",
	},
	{
		label: "Fuel Consumption",
		key: "fuel_consumption",
		type: "text",
	},
	{
		label: "Brake System",
		key: "brake_system",
		type: "text",
	},
	{
		label: "Length",
		key: "length",
		type: "number",
	},
	{
		label: "Width",
		key: "width",
		type: "number",
	},
	{
		label: "Height",
		key: "height",
		type: "number",
	},
	{
		label: "Kerb Weight",
		key: "kerb_weight",
		type: "number",
	},
	{
		label: "Fuel Tank",
		key: "fuel_tank",
		type: "number",
	},
	{
		label: "Market Price",
		key: "market_price",
		type: "number",
	},
	{
		label: "Front Tread",
		key: "front_tread",
		type: "text",
	},
	{
		label: "Rear Tread",
		key: "rear_tread",
		type: "text",
	},
	{
		label: "Tyres",
		key: "tyres",
		type: "text",
	},
	{
		label: "Tyres Rim",
		key: "tyres_rim",
		type: "text",
	},
	{
		label: "Front Suspension",
		key: "front_suspension",
		type: "text",
	},
	{
		label: "Rear Suspension",
		key: "rear_suspension",
		type: "text",
	},
	{
		label: "Exterior Colour",
		key: "exterior_colour",
		type: "text",
	},
	{
		label: "Warranty",
		key: "warranty",
		type: "text",
	},
	{
		label: "Equipment Highlight",
		key: "equipment_highlight",
		type: "text",
	},
	{
		label: "Front Airbag",
		key: "front_airbag",
		type: "number",
	},
	{
		label: "Rear Airbag",
		key: "rear_airbag",
		type: "number",
	},
	{
		label: "Seat",
		key: "seat",
		type: "number",
	},
	{
		label: "Fuel Economy",
		key: "fuel_economy",
		type: "number",
	},
]
