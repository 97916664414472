export const MainNav = [
	{
		icon: "pe-7s-folder",
		label: "Data Drive",
		to: "#/dashboard/drive",
	},
];

export const MyWheelsMenu = [
	{
		icon: "pe-7s-pin",
		label: "Post",
		to: "#/dashboard/post",
	},
	{
		icon: "pe-7s-video",
		label: "Video",
		to: "#/dashboard/video",
	},
	{
		icon: "pe-7s-car",
		label: "Car Brand",
		to: "#/dashboard/car-brand",
	},
	{
		icon: "pe-7s-car",
		label: "Car Model",
		to: "#/dashboard/car-model",
	},
	{
		icon: "pe-7s-car",
		label: "Model Variant",
		to: "#/dashboard/car-model-variant",
	},
	{
		icon: "pe-7s-car",
		label: "Featured Car Model",
		to: "#/dashboard/feature-car-model",
	},
	{
		icon: "pe-7s-menu",
		label: "Category",
		to: "#/dashboard/configcategory",
  },
  {
		icon: "pe-7s-ticket",
		label: "Tags",
		to: "#/dashboard/tags",
	},
	{
		icon: "pe-7s-trash",
		label: "Bin",
		to: "#/dashboard/bin",
	},
];
