import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import PetrolForm from "./Form.js";

import TagHOC from "./action";

class Tag extends Component {
	componentDidMount = () => this.props.getPetrol();

	render = () => {
		return (
			<>
				<PageTitle
					heading="Petrol"
					subheading="Listings of the latest petrol price in the system."
					icon="pe-7s-car icon-gradient bg-happy-itmeo"
					buttons={[
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<Card className="main-card mb-3">
								<CardBody>
                  <PetrolForm
                    { ... this.props }
                    onSubmit={ this.props.updatePetrol }
                  />
                </CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.props.onLoadTag && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(TagHOC)(Tag);
