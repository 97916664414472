import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import Pagination from "components/Pagination";
import CustomConfirmationForm from "components/Modal/confirmation.js";

import PostHOC from "./actions/index";
import CategoryHOC from "./actions/category";
import { queryHandler } from "utils/queryHandler";

class Post extends Component {
  componentDidMount = () => {
    let tempQeuery = queryHandler(this.props.searchParams);

    this.props.getPost(tempQeuery);
    this.props.getCategories();
  };

  componentDidUpdate = (pp) => {
    if (
      pp.flattenCategories !== this.props.flattenCategories &&
      this.props.flattenCategories?.[0]
    ) {
      let tempIndex = _.findIndex(
        this.props.searchParams,
        (val) => val.value === "slug_category_id"
      );
      if (tempIndex > -1) {
        let tempSearchParams = _.cloneDeep(this.props.searchParams);
        tempSearchParams[tempIndex]["options"] = this.props.flattenCategories;

        this.props.onChangePostHOC("searchParams", tempSearchParams);
      }
    }

    if (pp.pageIndex !== this.props.pageIndex) {
      let tempQeuery = queryHandler(this.props.searchParams);

      this.props.getPost(tempQeuery);
    }
  };

  renderPostTable = () => {
    return (
      <MyWheelsTable
        data={this.props.posts}
        showPagination={false}
        columnsContent={[
          {
            Header: "Title",
            accessorKey: "title",
            centerColumn: true,
            Cell: (val) => <>{val.title || "N/A"}</>,
          },
          {
            Header: "Type",
            accessorKey: "type",
            centerColumn: true,
            Cell: (val) => <>{val.type === "post" ? "Article" : "Video"}</>,
          },
          // {
          // 	Header: "Category",
          // 	accessorKey: "slug_category_id",
          // 	centerColumn: true,
          // 	Cell: (val) =>
          // 		_.find(this.props.flattenCategories, (temp) => {
          // 			return temp.id === val.slug_category_id;
          // 		})?.name ?? "-",
          // },
          {
            Header: "Tags",
            accessorKey: "tags",
            centerColumn: true,
            Cell: (val) => (
              <>
                {val.tags?.map((tagChild) => (
                  <p className="mb-0">{`- ${tagChild.name}`}</p>
                ))}
                {val.tags?.length < 1 && <span>-</span>}
              </>
            ),
          },
        ]}
        actionsContent={[
          {
            content: <i className="pe-7s-refresh btn-icon-wrapper"> </i>,
            actionID: "RestorePost",
            color: "success",
            tooltipContent: "Restore Post",
            onClick: (val) => {
              this.props.onChangePostHOC("selectedRestorePost", val);
            },
          },
          {
            content: <i className="pe-7s-trash btn-icon-wrapper"> </i>,
            actionID: "DeletePost",
            color: "danger",
            tooltipContent: "Permenantly Delete Post",
            onClick: (val) => {
              this.props.onChangePostHOC("selectedDeletePost", val);
            },
          },
        ]}
      />
    );
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Bin"
          subheading="Listings of all deleted posts present in the system."
          icon="pe-7s-trash icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Row>
            <Col md={12}>
              {/* <SearchForm
								searchParams={this.props.searchParams}
								onChangeHOCState={this.props.onChangePostHOC}
								getListAPI={this.props.getPost}
							/> */}
              <Card className="main-card mb-3">
                <CardBody>
                  {this.renderPostTable()}
                  <Pagination
                    pageIndex={this.props.pageIndex}
                    totalCount={this.props.postCounts}
                    onChangePage={(val) =>
                      this.props.onChangePostHOC("pageIndex", val)
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        {(this.props.onLoadPost || this.props.onLoadCategory) && (
          <LoadingOverlay />
        )}
        <CustomConfirmationForm
          open={this.props.selectedDeletePost !== null}
          title={"Remove post"}
          loading={this.props.onLoadPost}
          message={
            "This action is irreversible. Are you sure to delete the selected post permenantly? "
          }
          onClose={() => this.props.onChangePostHOC("selectedDeletePost", null)}
          onClickConfirm={() => {
            this.props.deletePost(this.props.selectedDeletePost.id);
          }}
        />
        <CustomConfirmationForm
          open={this.props.selectedRestorePost !== null}
          title={"Remove post"}
          loading={this.props.onLoadPost}
          message={"Are you sure to restore the post ?"}
          onClose={() =>
            this.props.onChangePostHOC("selectedRestorePost", null)
          }
          onClickConfirm={() => {
            this.props.updatePost({
              ...this.props.selectedRestorePost,
              status: this.props.selectedRestorePost.published_at
                ? "scheduled"
                : "draft",
            });
          }}
        />
      </>
    );
  };
}

export default compose(PostHOC, CategoryHOC)(Post);
