import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import BrandForm from "./Form.js";
import SearchForm from "components/SearchForm";
import { queryHandler } from "utils/queryHandler";

import BrandHOC from "./action";

class Brand extends Component {
	componentDidMount = () => {
		this.props.getBrand()
		window.addEventListener('keydown', this.handleKeyDown);
	};

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			let tempQeuery = queryHandler(this.props.searchParams);
			this.props.getBrand(tempQeuery);
		}
	};

	renderCreateBrand = () => {
		return (
			<ChinaPressModal
				size={"md"}
				isOpen={this.props.showCreateBrandModal}
				onClose={() =>
					this.props.onChangeBrandHOC("showCreateBrandModal", false)
				}>
				<BrandForm onSubmit={this.props.createBrand} />
			</ChinaPressModal>
		);
	};

	renderEditBrand = () => {
		return (
			<ChinaPressModal
				size={"md"}
				isOpen={this.props.showEditBrandModal}
				onClose={() => {
					this.props.onChangeBrandHOC("selectedBrand", null);
					this.props.onChangeBrandHOC("showEditBrandModal", false);
				}}>
				<BrandForm
					selectedBrand={this.props.selectedBrand}
					onSubmit={this.props.updateBrand}
				/>
			</ChinaPressModal>
		);
	};

	renderBrandTable = () => {
		return (
			<MyWheelsTable
				data={this.props.brands}
				columnsContent={[
					{
						Header: "Name",
						accessorKey: "name",
						centerColumn: true,
						Cell: (val) => <>{val.name}</>,
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						Cell: (val) => (
							<>
								<span
									className={`badge ${val.status === "live" ? "badge-success" : "badge-secondary"
										}`}>
									{val.status}
								</span>
							</>
						),
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditPost",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.onChangeBrandHOC("selectedBrand", val);
							this.props.onChangeBrandHOC("showEditBrandModal", true);
						},
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading="Car Brands"
					subheading="Listings of all the car brands present in the system."
					icon="pe-7s-car icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeBrandHOC("showCreateBrandModal", true);
							},
							content: (
								<>
									<span>Create Brand</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col>
							<SearchForm
								searchParams={this.props.searchParams}
								onChangeHOCState={this.props.onChangeBrandHOC}
								getListAPI={this.props.getBrand}
							/>
						</Col>
						<Col md={12}>
							<Card className="main-card mb-3">
								<CardBody>{this.renderBrandTable()}</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreateBrand()}
				{this.renderEditBrand()}
				{this.props.onLoadBrand && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(BrandHOC)(Brand);
