import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
	Form,
	Input,
	FormGroup,
	Label,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

export default function ConfigCategoryForm({ onSubmit }) {
	const [configCategoryForm, setConfigCategoryForm] = useState({ 
		name: '',
	 });

	const onChangeField = (key, val) => {
		setConfigCategoryForm({
			...configCategoryForm,
			[key]: val,
		});
	};

	return (
		<>
			<CustomModalBody>
				<Form>
					<FormGroup>
						<Label>
							Name
							<span className="text-danger">*</span>
						</Label>
						<Input
							placeholder="Add Category Name"
							value={configCategoryForm.name}
							onChange={(e) => onChangeField("name", e.target.value)}
						/>
					</FormGroup>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "primary",
						content: "Save",
						disabled: !configCategoryForm.name,
						onClick: () => {
							let temp = _.cloneDeep(configCategoryForm);

							onSubmit(temp);
						},
					},
				]}
			/>
		</>
	);
}
