import Moment from "moment";

export const SamplePostData = {
	platform: "myWheels",
	views: 0,
	start_at: `${Moment()}`,
	title: "",
	content: "",
	cover_img_with_title_url: "",
	cover_img_without_title_url: "",
	created_by: "",
	short_url: "",
	is_pin: false,
	status: "scheduled",
	type: "post",
	likes: 0,

	mywheels_tags: [],
	article_feature_img_url: "",
	slug: "",
	photographer_name: "",
	author_name: "",
	slug_category_id: "",
	further_reading_post_id: null,
};

export const RootCategories = [
	{
		name: "综合",
		id: 2,
		children: [],
	},
	{
		name: "两轮天地",
		id: 3,
		children: [],
	},
	{
		name: "试驾",
		id: 4,
		children: [],
	},
	{
		name: "新车",
		id: 1,
		children: [],
	},
];
