import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { ModalHeader } from "reactstrap";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import MyWheelsTable from "components/Table";
import HOC from "../actions";
import SearchForm from "components/SearchForm";
import Pagination from "components/Pagination";
import { queryHandler } from "utils/queryHandler";

function FurtherReadingPost({
	pageIndex,
	searchParams,
	posts,
	flattenCategories,
	getPost,
	onSubmit,
	postCounts,
	onChangePostHOC,
}) {
	const [selectedPost, setSelectedPost] = useState(null);
	useEffect(() => {
		let tempQeuery = queryHandler(searchParams);

		getPost(tempQeuery);
	}, [pageIndex]);

	return (
		<>
			<CustomModalBody>
				<SearchForm
					searchParams={searchParams}
					onChangeHOCState={onChangePostHOC}
					getListAPI={getPost}
				/>
				<MyWheelsTable
					data={posts}
					columnsContent={[
						{
							Header: "Title",
							centerColumn: true,
							Cell: (val) => <>{val.title || "N/A"}</>,
						},
						{
							Header: "Pin",
							centerColumn: true,
							Cell: (val) => val.position || "-",
						},
						{
							Header: "Category",
							centerColumn: true,
							accessorKey: "slug_category_id",
							Cell: (val) => val?.slug_category?.name ?? "-",
						},
						{
							Header: "Tags",
							centerColumn: true,
							Cell: (val) => (
								<>
									{val.tags.map((tagChild) => (
										<p className="mb-0">{`- ${tagChild.name}`}</p>
									))}
									{val.tags.length < 1 && <span>-</span>}
								</>
							),
						},
						{
							Header: "Status",
							centerColumn: true,
							Cell: (val) => (
								<>
									<span
										className={`badge ${
											val.status === "active"
												? "badge-success"
												: "badge-secondary"
										}`}>
										{val.status}
									</span>
								</>
							),
						},
						{
							Header: "Schedule Date",
							centerColumn: true,
							Cell: (val) => (
								<>{val.start_at ? Moment(val.start_at).format("DD-MM-YYYY h:mm a") : '-'}</>
							),
						},
						{
							Header: "Published Date",
							centerColumn: true,
							Cell: (val) => (
								<>
									{Moment(val.start_at).isBefore(Moment())
										? Moment(val.start_at).format("DD-MM-YYYY h:mm a")
										: "-"}
								</>
							),
						},
					]}
					actionsContent={[
						{
							content: () => "Select",
							actionID: "SelectPost",
							colorFunc: (val) =>
								selectedPost && val.id === selectedPost.id
									? "primary"
									: "secondary",
							tooltipContent: "Select",
							onClick: (val) => {
								if (selectedPost && val.id === selectedPost.id) {
									return setSelectedPost(null);
								}
								setSelectedPost(val);
							},
						},
					]}
				/>
				<Pagination
					pageIndex={pageIndex}
					totalCount={postCounts}
					onChangePage={(val) => onChangePostHOC("pageIndex", val)}
				/>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						content: "Submit",
						disabled: !selectedPost,
						color: "primary",
						onClick: () => {
							onSubmit(selectedPost);
						},
					},
				]}
			/>
		</>
	);
}

export default HOC(FurtherReadingPost);
