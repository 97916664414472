import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import CreateVariantForm from "./Create.js";
import EditVariantForm from "./Edit.js";
import { queryHandler } from "utils/queryHandler";
import SearchForm from "components/SearchForm";
import _ from "lodash";


import ModelHOC from "./actions/index.js";
import "./index.scss";

class Model extends Component {
	componentDidMount = () => {
		let tempQeuery = queryHandler(this.props.searchParams);
		this.props.getModel();
		this.props.getBrand();
		this.props.getVariant(tempQeuery);
		window.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			let tempQeuery = queryHandler(this.props.searchParams);
			this.props.getVariant(tempQeuery);
		}
	};

	componentDidUpdate = (pp) => {
		if (
			pp.brands !== this.props.brands &&
			this.props.brands?.[0]
		) {
			let tempIndex = _.findIndex(
				this.props.searchParams,
				(val) => val.value === "brand"
			);
			if (tempIndex > -1) {
				let tempSearchParams = _.cloneDeep(this.props.searchParams);
				tempSearchParams[tempIndex]["options"] = this.props.brands;

				this.props.onChangeModelHOC("searchParams", tempSearchParams);
			}
		}

		if (pp.pageIndex !== this.props.pageIndex) {
			let tempQeuery = queryHandler(this.props.searchParams);
			this.props.getVariant(tempQeuery);
		}

		if (
			pp.models !== this.props.models &&
			this.props.models?.[0]
		) {
			let tempIndex = _.findIndex(
				this.props.searchParams,
				(val) => val.value === "model"
			);
			if (tempIndex > -1) {
				let tempSearchParams = _.cloneDeep(this.props.searchParams);
				tempSearchParams[tempIndex]["options"] = this.props.models.map((eachModel) => { return { id: eachModel.id, name: eachModel.title } });
				this.props.onChangeModelHOC("searchParams", tempSearchParams);
			}
		}

		if (pp.pageIndex !== this.props.pageIndex) {
			let tempQeuery = queryHandler(this.props.searchParams);
			this.props.getVariant(tempQeuery);
		}
	};


	renderCreateVariant = () => {
		return (
			<ChinaPressModal
				size={"xl"}
				isOpen={this.props.showCreateVariantModal}
				onClose={() =>
					this.props.onChangeModelHOC("showCreateVariantModal", false)
				}>
				<CreateVariantForm
					models={this.props.models}
					brands={this.props.brands}
					onSubmit={this.props.createVariant}
				/>
			</ChinaPressModal>
		);
	};

	renderEditVariant = () => {
		return (
			<ChinaPressModal
				size={"xl"}
				isOpen={this.props.showEditVariantModal}
				onClose={() => {
					this.props.onChangeModelHOC("selectedVariant", null);
					this.props.onChangeModelHOC("showEditVariantModal", false);
				}}>
				<EditVariantForm
					models={this.props.models}
					brands={this.props.brands}
					selectedVariant={this.props.selectedVariant}
					onSubmit={this.props.updateVariant}
				/>
			</ChinaPressModal>
		);
	};

	renderVariantTable = () => {
		return (
			<MyWheelsTable
				data={this.props.variants}
				columnsContent={[
					{
						Header: "Variant Name",
						accessorKey: "title",
						centerColumn: true,
						Cell: (val) => <>{val?.title}</>,
					},
					{
						Header: "Brand",
						accessorKey: "",
						centerColumn: true,
						Cell: val => (
							<>
								{val?.car_brand_model?.brand ?? ''}
							</>
						)
					},
					{
						Header: "Model",
						accessorKey: "",
						centerColumn: true,
						Cell: val => (
							<>
								{val?.car_brand_model?.model ?? ''}
							</>
						)
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						Cell: (val) => (
							<>
								<span
									className={`badge ${val.status === "active" ? "badge-success" : "badge-secondary"
										}`}>
									{val.status}
								</span>
							</>
						),
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditVariant",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.onChangeModelHOC("selectedVariant", val);
							this.props.onChangeModelHOC("showEditVariantModal", true);
						},
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading="Car Model Variants"
					subheading="Listings of all the car model variants present in the system."
					icon="pe-7s-car icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeModelHOC("showCreateVariantModal", true);
							},
							content: (
								<>
									<span>Create Variant</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col>
							<SearchForm
								searchParams={this.props.searchParams}
								onChangeHOCState={this.props.onChangeModelHOC}
								getListAPI={this.props.getVariant}
							/>
						</Col>
						<Col md={12}>
							<Card className="main-card mb-3">
								<CardBody>{this.renderVariantTable()}</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreateVariant()}
				{this.renderEditVariant()}
				{this.props.onLoadModel && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(ModelHOC)(Model);
