import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			assets: {
				data: [],
				count: 0,
			},
			selectedViewDriveData: null,
			selectedAsset: null,
		};

		onChangeAssetsHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getAssets = (platform, pageIndex) => {
			Get(
				`/assets?platform=${platform}&offset=${(pageIndex - 1) * 25}&limit=25`,
				this.getAssetsSuccess,
				this.getAssetsError,
				this.load
			);
		};
		getAssetsSuccess = (payload) => this.setState({ assets: payload.data });
		getAssetsError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					assets={this.state.assets}
					selectedAsset={this.state.selectedAsset}
					onLoadAssets={this.state.loading}
					selectedViewDriveData={this.state.selectedViewDriveData}
					getAssets={this.getAssets}
					onChangeAssetsHOC={this.onChangeAssetsHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
