import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			postCarBrands: [],
		};

		onChangePostCarBrandHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPostCarBrand = (id) => {
			Get(
				`/mywheels/post/car-brands?post_id=${id}`,
				this.getPostCarBrandSuccess,
				this.getPostCarBrandError,
				this.load
			);
		};
		getPostCarBrandSuccess = (payload) =>
			this.setState({ postCarBrands: payload.data });
		getPostCarBrandError = (error) => requestError(error);

		createPostCarBrand = (dataToSubmit) =>
			Post(
				`/mywheels/post/car-brands`,
				dataToSubmit,
				this.createPostCarBrandSuccess,
				this.createPostCarBrandError,
				this.load
			);
		createPostCarBrandSuccess = (payload) => {
			this.getPostCarBrand(payload.data.post_id);
			requestSuccess("Categorys has been added successfully.");
		};
		createPostCarBrandError = (error) => requestError(error);

		deletePostCarBrand = (id, postID) =>
			Delete(
				`/mywheels/post/car-brands/${id}`,
				() => this.deletePostCarBrandSuccess(postID),
				this.deletePostCarBrandError,
				this.load
			);
		deletePostCarBrandSuccess = (postID) => {
			this.setState({ showDeleteModal: false });
			this.getPostCarBrand(postID);
			requestSuccess("Category has been deleted succesfully.");
		};
		deletePostCarBrandError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCarBrands={this.state.postCarBrands}
					onLoadPostCarBrand={this.state.loading}
					getPostCarBrand={this.getPostCarBrand}
					createPostCarBrand={this.createPostCarBrand}
					deletePostCarBrand={this.deletePostCarBrand}
					onChangePostCarBrandHOC={this.onChangePostCarBrandHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
