import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			brands: [],
			searchParams: [
				{
					label: "Name",
					value: `name`,
					type: "text",
					param: ``,
					col: 6,
				},
			],
		};

		onChangeBrandHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBrand = (val) => {
			Get(
				`/mywheels/car-brands?${val}`,
				this.getBrandSuccess,
				this.getBrandError,
				this.load
			);
		};

		getBrandSuccess = (payload) => this.setState({ brands: payload.data });

		getBrandError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					searchParams={this.state.searchParams}
					brands={this.state.brands}
					getBrand={this.getBrand}
					onChangeBrandHOC={this.onChangeBrandHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
