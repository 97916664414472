import React, { useState, useEffect, Fragment, useRef } from "react";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";
import { Typeahead } from "react-bootstrap-typeahead";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  Button,
} from "reactstrap";
import { AiOutlinePlus, AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";

import { SampleVideoData } from "../assets";
import ImageField from "components/Input/File";
import CustomModal from "components/Modal";
import CategoryContent from "./Category";
import CarModelModal from "components/Modal/CarModel";
import CarModelBrand from "components/Modal/CarBrand";

import CarModelHOC from "../actions/carModel";
import CarBrandHOC from "../actions/carBrand";

import "react-datepicker/dist/react-datepicker.css";

const VideoForm = ({
  videographers,
  reporters,
  userID,
  tags,
  categories,
  flattenCategories,
  onSubmit,
  createTag,
  selectedVideo,
  selectedVideoTags,
  postCarModels,
  postCarBrands,

  getVideoTags,
  addVideoTag,
  deleteVideoTag,
  deletePostCarModel,
  createPostCarModel,
  createPostCarBrand,
  deletePostCarBrand,
  getPostCarModel,
  getPostCarBrand,

  addNewReporterOrVideographer,
}) => {
  const typeaheadRef = useRef();
  const videographerTypeaheadRef = useRef();
  const reporterTypeaheadRef = useRef();

  const [typeaheadSearch, setTypeaheadSearch] = useState("");
  const [reporterSearch, setReporterSearch] = useState("");
  const [videographerSearch, setVideographerSearch] = useState("");

  const [allowToCreateTag, setAllowCreate] = useState(false);
  const [showAddModel, setAddModel] = useState(false);
  const [showAddBrand, setAddBrand] = useState(false);
  const [postForm, setVideoForm] = useState({
    ...SampleVideoData,
    created_by: userID,
  });

  const [videoGrapher, setVideoGrapher] = useState("");
  const [reporter, setReporter] = useState("");

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [typeaheadSearch]);

  const onChangeField = (key, val) => {
    setVideoForm({
      ...postForm,
      [key]: val,
    });
  };

  const handleKeyDown = async (event) => {
    try {
      if (event.key === "Enter" && typeaheadSearch.length > 0) {
        const matchedOptions = tags.filter((option) => {
          return (
            option.label
              .toLowerCase()
              .includes(typeaheadSearch.toLowerCase()) &&
            !selectedVideoTags.some((eachTag) => eachTag.name === option.label)
          );
        });
        const selectedTag = matchedOptions[0];
        if (selectedTag) {
          addVideoTag(selectedVideo.id, selectedTag.id);
          typeaheadRef.current.clear();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedVideo) {
      getPostCarModel(selectedVideo.id);
      getPostCarBrand(selectedVideo.id);
      getVideoTags(selectedVideo.id);
      const selectedAuthor = reporters.find(
        (eachReporter) => eachReporter.id === selectedVideo.author_id
      );
      const selectedVideographer = videographers.find(
        (eachVideographer) =>
          eachVideographer.id === selectedVideo.photographer_id
      );
      if (selectedVideographer) setVideoGrapher([selectedVideographer.name]);
      if (selectedAuthor) setReporter([selectedAuthor.name]);
    }
  }, [selectedVideo, videographers, reporters]);

  useEffect(() => {
    if (selectedVideo && selectedVideoTags) {
      setVideoForm({
        ...selectedVideo,
        mywheels_tags: selectedVideoTags,
      });
    }
  }, [selectedVideo, selectedVideoTags]);

  return (
    <>
      <CustomModalBody fullScreen={true}>
        <Form>
          <Row>
            <Col md={12} lg={6} xl={8}>
              <FormGroup>
                <Label>
                  Title
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Add Title"
                  value={postForm.title}
                  onChange={(e) => onChangeField("title", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Video Embed URL</Label>
                <Input
                  placeholder="Add Video Embed URL"
                  value={postForm.video_embed_url}
                  onChange={(e) =>
                    onChangeField("video_embed_url", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Introduction</Label>
                <Input
                  type={"textarea"}
                  placeholder="Add Introduction"
                  value={postForm.introduction}
                  onChange={(e) =>
                    onChangeField("introduction", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <ImageField
                  storagePath="videos"
                  label={"Cover Image with Title"}
                  fileValue={postForm.cover_img_with_title_url}
                  onRemoveFile={() =>
                    onChangeField("cover_img_with_title_url", "")
                  }
                  onSelectImage={(val) =>
                    onChangeField("cover_img_with_title_url", val)
                  }
                />
              </FormGroup>
              <FormGroup>
                <ImageField
                  storagePath="videos"
                  label={"Cover Image without Title"}
                  fileValue={postForm.cover_img_without_title_url}
                  onRemoveFile={() =>
                    onChangeField("cover_img_without_title_url", "")
                  }
                  onSelectImage={(val) =>
                    onChangeField("cover_img_without_title_url", val)
                  }
                />
              </FormGroup>
              {/* <FormGroup>
								<ImageField
									label={"Article Feature Image"}
									fileValue={postForm.article_feature_img_url}
									onRemoveFile={() =>
										onChangeField("article_feature_img_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("article_feature_img_url", val)
									}
								/>
							</FormGroup> */}
              <Card className="mb-3">
                <CardHeader>
                  <div className="d-flex align-items-end w-100">
                    <span>Car Brands</span>
                    <div className="ml-auto">
                      <Button
                        size={"sm"}
                        color={"primary"}
                        onClick={() => setAddBrand(true)}
                      >
                        <AiOutlinePlus style={{ width: 12, height: 12 }} />
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {postCarBrands.length < 1 && (
                    <div className="text-center">No brands is selected</div>
                  )}
                  {postCarBrands.map((brandChild, brandIndex) => (
                    <Fragment key={`tag_${brandChild.id}`}>
                      <span
                        className="badge badge-white p-2"
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: 15,
                        }}
                      >
                        {brandChild.name}
                        <AiOutlineCloseCircle
                          className={"bg-white ml-1"}
                          style={{
                            cursor: "pointer",
                            width: 13,
                            height: 13,
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            deletePostCarBrand(brandChild.id, selectedVideo.id);
                          }}
                        />
                      </span>
                    </Fragment>
                  ))}
                </CardBody>
              </Card>

              <Card className="mb-3">
                <CardHeader>
                  <div className="d-flex align-items-end w-100">
                    <span>Car Models</span>
                    <div className="ml-auto">
                      <Button
                        size={"sm"}
                        color={"primary"}
                        onClick={() => setAddModel(true)}
                      >
                        <AiOutlinePlus style={{ width: 12, height: 12 }} />
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {postCarModels.length < 1 && (
                    <div className="text-center">No models is selected</div>
                  )}
                  {postCarModels.map((modelChild, modelIndex) => (
                    <Fragment key={`tag_${modelChild.id}`}>
                      <span
                        className="badge badge-white p-2"
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: 15,
                        }}
                      >
                        {modelChild.title}
                        <AiOutlineCloseCircle
                          className={"bg-white ml-1"}
                          style={{
                            cursor: "pointer",
                            width: 13,
                            height: 13,
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            deletePostCarModel(modelChild.id, selectedVideo.id);
                          }}
                        />
                      </span>
                    </Fragment>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col md={12} lg={6} xl={4} className="mywheels-post-form">
              <Card className="mb-3">
                <CardHeader>Article Details</CardHeader>
                <CardBody>
                  <FormGroup>
                    <FormGroup>
                      <Label>Schedule Date Time</Label>
                      <DatePicker
                        selected={
                          postForm.start_at ? new Date(postForm.start_at) : null
                        }
                        className="form-control"
                        minDate={Moment().toDate()}
                        showYearDropdown
                        popperPlacement="right-start"
                        onChange={(val) => onChangeField("start_at", val)}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Main Category
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type={"select"}
                        value={postForm.slug_category_id}
                        onChange={(e) =>
                          onChangeField("slug_category_id", +e.target.value)
                        }
                      >
                        <option value=""></option>
                        {flattenCategories.map((categoryChild) => (
                          <option
                            key={`category_${categoryChild.id}`}
                            value={categoryChild.id}
                          >
                            {categoryChild.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <CategoryContent
                        selectedVideo={selectedVideo}
                        categories={categories}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>URL endpoint</Label>
                      <Input
                        value={postForm.slug_field}
                        placeholder={postForm.title}
                        onChange={(e) =>
                          onChangeField("slug_field", e.target.value)
                        }
                      />
                    </FormGroup>
                    <Label>Shorten URL</Label>
                    <Input
                      value={`${process.env.REACT_APP_MYWHEELS_WEB_URL}/articles${postForm.short_url}`}
                      disabled={true}
                      readOnly={true}
                    />
                  </FormGroup>
                  <Form>
                    <FormGroup check>
                      <Input
                        name="dont-pin"
                        checked={!postForm.position && !postForm.is_pin}
                        type="radio"
                        onClick={() => {
                          let temp = _.cloneDeep(postForm);
                          temp.is_pin = false;
                          delete temp.position;

                          setVideoForm(temp);
                        }}
                      />{" "}
                      <Label check>Don't Pin</Label>
                    </FormGroup>
                    {[1, 2, 3, 4].map((index) => (
                      <>
                        <FormGroup check>
                          <Input
                            name={`radio${index}`}
                            type="radio"
                            checked={
                              postForm.position === index && postForm.is_pin
                            }
                            onClick={() => {
                              let temp = _.cloneDeep(postForm);
                              temp.is_pin = true;
                              temp.position = index;

                              setVideoForm(temp);
                            }}
                          />{" "}
                          <Label check>Pin at Slider No. {` ${index}`}</Label>
                        </FormGroup>
                      </>
                    ))}
                  </Form>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardHeader>Tags</CardHeader>
                <CardBody>
                  {postForm.mywheels_tags.length < 1 && (
                    <div className="text-center">No tags is selected</div>
                  )}
                  <div className="d-flex flex-wrap mb-3" style={{ gap: 5 }}>
                    {postForm.mywheels_tags.length > 0 &&
                      postForm.mywheels_tags.map((tagChild) => (
                        <Fragment key={`tag_${tagChild.id}`}>
                          <span
                            className="badge badge-white p-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: 15,
                            }}
                          >
                            {`# `} {tagChild.name}
                            <AiOutlineCloseCircle
                              className={"bg-white ml-1"}
                              style={{
                                cursor: "pointer",
                                width: 13,
                                height: 13,
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                deleteVideoTag(tagChild.id, tagChild.post_id);
                              }}
                            />
                          </span>
                        </Fragment>
                      ))}
                  </div>
                  <div className="d-flex w-100 alignt-items-center flex-wrap">
                    <Typeahead
                      ref={typeaheadRef}
                      id="basic-example"
                      onInputChange={(e) => {
                        const matchedOptions = tags.filter((option) =>
                          option.label.toLowerCase().includes(e.toLowerCase())
                        );

                        setAllowCreate(matchedOptions.length < 1);
                        setTypeaheadSearch(e);
                      }}
                      onChange={(val) => {
                        addVideoTag(selectedVideo.id, val?.[0]?.id);
                      }}
                      options={[
                        ..._.filter(tags, (tagChild) => {
                          let tempIndex =
                            _.findIndex(postForm.mywheels_tags, {
                              mywheels_tag_id: tagChild.id,
                            }) < 0;

                          return tempIndex;
                        }),
                      ]}
                      placeholder="Choose a tag"
                      selected={[]}
                    />
                    <Button
                      size={"sm"}
                      disabled={!typeaheadSearch || !allowToCreateTag}
                      className="ml-auto"
                      onClick={() => {
                        typeaheadRef.current.clear();
                        createTag(
                          {
                            name: typeaheadSearch,
                          },
                          (val) => {
                            addVideoTag(selectedVideo.id, val.id);
                          }
                        );
                      }}
                    >
                      Create
                    </Button>
                  </div>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <FormGroup>
                    <Label>Videographer</Label>
                    {videoGrapher.length < 0 && (
                      <div className="text-center">
                        No videographer is selected
                      </div>
                    )}
                    <div className="flex w-full mb-3">
                      {videoGrapher.length > 0 && (
                        <Fragment key={`tag_${videoGrapher}`}>
                          <span
                            className="badge badge-white p-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: 15,
                            }}
                          >
                            {`# `} {videoGrapher}
                            <AiOutlineCloseCircle
                              className={"bg-white ml-1"}
                              style={{
                                cursor: "pointer",
                                width: 13,
                                height: 13,
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setVideoGrapher("");
                              }}
                            />
                          </span>
                        </Fragment>
                      )}
                    </div>
                    <div className="d-flex w-100 alignt-items-center flex-wrap">
                      <Typeahead
                        ref={videographerTypeaheadRef}
                        labelKey="name"
                        onInputChange={(e) => {
                          setVideographerSearch(e);
                        }}
                        onChange={(val) => {
                          setVideoGrapher(val);
                          const selectedVideoGrapher = videographers.find(
                            (eachVideographer) => {
                              return eachVideographer.name == val;
                            }
                          );
                          if (selectedVideoGrapher)
                            postForm.photographer_id = selectedVideoGrapher.id;
                          else postForm.photographer_name = null;
                        }}
                        options={videographers.map(
                          (eachVideographer) => eachVideographer.name
                        )}
                        placeholder="Choose a videographer..."
                        selected={videoGrapher}
                      />
                      <Button
                        size={"sm"}
                        disabled={!videographerSearch}
                        className="ml-auto"
                        onClick={async () => {
                          videographerTypeaheadRef.current.clear();
                          if (videographerSearch) {
                            const videographerData =
                              await addNewReporterOrVideographer(
                                { name: videographerSearch },
                                "videographer"
                              );
                            postForm["photographer_id"] = videographerData.id;
                            postForm["photographer_name"] =
                              videographerData.name;
                            setVideoGrapher([videographerData.name]);
                            setVideographerSearch("");
                          }
                        }}
                      >
                        Create
                      </Button>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Reporter</Label>
                    {reporter.length < 0 && (
                      <div className="text-center">No reporter is selected</div>
                    )}
                    <div className="flex w-full mb-3">
                      {reporter.length > 0 && (
                        <Fragment key={`tag_${reporter}`}>
                          <span
                            className="badge badge-white p-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: 15,
                            }}
                          >
                            {`# `} {reporter}
                            <AiOutlineCloseCircle
                              className={"bg-white ml-1"}
                              style={{
                                cursor: "pointer",
                                width: 13,
                                height: 13,
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setReporter("");
                              }}
                            />
                          </span>
                        </Fragment>
                      )}
                    </div>
                    <div className="d-flex w-100 alignt-items-center flex-wrap">
                      <Typeahead
                        ref={reporterTypeaheadRef}
                        labelKey="name"
                        onInputChange={(e) => {
                          setReporterSearch(e);
                        }}
                        onChange={(val) => {
                          setReporter(val);
                          const selectedReporter = reporters.find(
                            (eachReporter) => {
                              return eachReporter.name == val;
                            }
                          );
                          if (selectedReporter)
                            postForm.author_id = selectedReporter.id;
                          else postForm.author_id = null;
                        }}
                        options={reporters.map(
                          (eachReporter) => eachReporter.name
                        )}
                        placeholder="Choose a reporter..."
                        selected={reporter}
                      />
                      <Button
                        size={"sm"}
                        disabled={!reporterSearch}
                        className="ml-auto"
                        onClick={async () => {
                          reporterTypeaheadRef.current.clear();
                          if (reporterSearch) {
                            const reporterData =
                              await addNewReporterOrVideographer(
                                { name: reporterSearch },
                                "reporter"
                              );
                            postForm["author_id"] = reporterData.id;
                            postForm["author_name"] = reporterData.name;
                            setReporter([reporterData.name]);
                            setReporterSearch("");
                          }
                        }}
                      >
                        Create
                      </Button>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "primary",
            content: "Save",
            disabled: !postForm.title || !postForm.slug_category_id,
            onClick: async () => {
              onSubmit({
                ...postForm,
              });
            },
          },
          {
            color: "primary",
            content:
              postForm.status !== "draft"
                ? "Save As Draft"
                : "Saved As Scheduled",
            disabled: !postForm.title || !postForm.slug_category_id,
            onClick: async () => {
              let tempForm = _.cloneDeep(postForm);

              tempForm.status =
                postForm.status === "scheduled" || postForm.status === "live"
                  ? "draft"
                  : "scheduled";

              if (tempForm.status === "live") {
                tempForm.published_at = `${Moment()}`;
              }

              if (tempForm.status === "draft") {
                tempForm.published_at = null;
              }

              return onSubmit({ ...tempForm });
            },
          },
          {
            color: "success",
            content: "Live Now",
            disabled:
              !postForm.title ||
              !postForm.slug_category_id ||
              postForm.status === "live",
            onClick: async () => {
              let tempForm = _.cloneDeep(postForm);

              tempForm.status = "live";
              tempForm.start_at = new Date();
              tempForm.published_at = new Date();

              return onSubmit({ ...tempForm });
            },
          },
          {
            color: "warning",
            content: <span className="text-white">Preview</span>,
            onClick: () =>
              window.open(
                `${process.env.REACT_APP_MYWHEELS_WEB_URL}/preview${selectedVideo.slug}`,
                "_blank"
              ),
          },
        ]}
      />
      <CustomModal isOpen={showAddModel} onClose={() => setAddModel(false)}>
        <CarModelModal
          mode={"edit"}
          postCarModels={postCarModels}
          onSubmit={(val) => {
            createPostCarModel({
              post_id: selectedVideo.id,
              mywheels_car_brand_model_id: val.id,
            });
            setAddModel(false);
          }}
        />
      </CustomModal>
      <CustomModal isOpen={showAddBrand} onClose={() => setAddBrand(false)}>
        <CarModelBrand
          mode={"edit"}
          postCarBrands={postCarBrands}
          onSubmit={(val) => {
            setAddBrand(false);
            createPostCarBrand({
              post_id: selectedVideo.id,
              mywheels_car_brand_id: val.id,
            });
          }}
        />
      </CustomModal>
    </>
  );
};

export default compose(CarModelHOC, CarBrandHOC)(VideoForm);
