import React, { useEffect, useState } from "react";
import CustomModal from "./index";
import AssetsTable from "components/Table/Asset";
import CustomModalBody from "components/Modal/body";
import AssetsHOC from "actions/hoc/assets";
import CustomPagination from "components/Pagination";

const MediaDBModal = ({ isOpen, onSelect, onClose, ...props }) => {
	const [pageIndex, setPageIndex] = useState(1);

	useEffect(() => {
		if (isOpen) {
			let tempPlatforms = "";
			if (window.location.hash.includes("job-macha")) {
				tempPlatforms = "jobmacha";
			} else if (window.location.hash.includes("service-macha")) {
				tempPlatforms = "servicemacha";
			} else {
				tempPlatforms = "myWheels";
			}
			props.getAssets(tempPlatforms, pageIndex);
		}
	}, [isOpen, pageIndex]);

	return (
		<CustomModal isOpen={isOpen} size={"xl"} onClose={onClose}>
			<CustomModalBody>
				<AssetsTable
					mode={"view"}
					assets={props.assets?.rows ?? []}
					onSelect={onSelect}
					multiSelect={props.multiSelect}
					onClickElement={(val) =>
						props.onChangeAssetsHOC("selectedAsset", val)
					}
				/>
				<CustomPagination
					perPage={25}
					pageIndex={pageIndex}
					totalCount={props.assets.count}
					onChangePage={(val) => setPageIndex(val)}
				/>
			</CustomModalBody>
		</CustomModal>
	);
};

export default AssetsHOC(MediaDBModal);
