import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			selectedPetrol: null,

			showCreateModal: false,
			showDeleteModal: false,
			showUpdateModal: false,
		};

		onChangePetrolHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPetrol = () => {
			Get(`/petrols`, this.getPetrolSuccess, this.getPetrolError, this.load);
		};
		getPetrolSuccess = (payload) => this.setState({ selectedPetrol: payload.data[0] });
		getPetrolError = (error) => requestError(error);

		updatePetrol = (dataToSubmit) =>
			Put(
				`/petrols/${ dataToSubmit.id }`,
				dataToSubmit,
				this.updatePetrolSucces,
				this.updatePetrolError,
				this.load
			);
		updatePetrolSucces = () => {
			this.getPetrol();
			requestSuccess("Petrol price updated successfully.");
		};
		updatePetrolError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					selectedPetrol={ this.state.selectedPetrol }
					onLoadPetrol={this.state.loading}

					getPetrol={this.getPetrol}
					getSelectedPetrol={this.getSelectedPetrol}
					updatePetrol={this.updatePetrol}
					onChangePetrolHOC={this.onChangePetrolHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
