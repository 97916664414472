import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import MyWheelsTable from "components/Table";
import HOC from "actions/hoc/model";
import SearchForm from "components/SearchForm";

function FurtherReadingPost({
	searchParams,
	mode,
	models,
	postCarModels,
	getModel,
	getBrand,
	onSubmit,
	onChangeModelHOC
}) {
	const [selectedModel, setSelectedModel] = useState(null);

	useEffect(() => {
		getModel();
		getBrand();
	}, []);

	const filtered = useMemo(() => {
		let temp = _.filter(models, (modelChild) => {
			return (
				_.findIndex(postCarModels, (postModelChild) => {
					if (mode === "edit") {
						return postModelChild.mywheels_car_brand_model_id === modelChild.id;
					} else {
						return postModelChild.id === modelChild.id;
					}
				}) < 0
			);
		});

		return temp;
	}, [postCarModels, models]);


	return (
		<>
			<CustomModalBody>
				<SearchForm
					getListAPI={getModel}
					onChangeHOCState={onChangeModelHOC}
					searchParams={searchParams}
				/>
				<MyWheelsTable
					showPagination={true}
					data={filtered}
					columnsContent={[
						{
							Header: "Model Title",
							accessorKey: "title",
							centerColumn: true,
							Cell: (val) => <>{val.title}</>,
						},
						{
							Header: "Brand",
							accessorKey: "",
							centerColumn: true,
							Cell: (val) => <>{val?.mywheels_car_brands?.name ?? ""}</>,
						},
					]}
					actionsContent={[
						{
							content: () => "Select",
							actionID: "SelectPost",
							colorFunc: (val) =>
								selectedModel && val.id === selectedModel.id
									? "primary"
									: "secondary",
							tooltipContent: "Select",
							onClick: (val) => {
								if (selectedModel && val.id === selectedModel.id) {
									return setSelectedModel(null);
								}
								setSelectedModel(val);
							},
						},
					]}
				/>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						content: "Submit",
						disabled: !selectedModel,
						color: "primary",
						onClick: () => {
							onSubmit(selectedModel);
						},
					},
				]}
			/>
		</>
	);
}

export default HOC(FurtherReadingPost);
