import React from "react";
import { Label } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import CustomModal from "components/Modal";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";

const Confirmation = ({
	open,
	title,
	loading,
	message,

	onClose,
	onClickConfirm,
}) => {
	return (
		<CustomModal isOpen={open} onClose={() => onClose()}>
			<CustomModalBody>
				<Label>{message}</Label>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						content: "Confirm",
						onClick: () => onClickConfirm(),
					},
				]}
			/>
			{loading && <LoadingOverlay />}
		</CustomModal>
	);
};

export default Confirmation;
