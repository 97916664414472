import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			categories: [],
			flattenCategories: [],
		};

		onChangeSampleHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		handleGetCategoryPromise = (index) => {
			return new Promise((resolve, reject) => {
				Get(
					`/mywheels/find-children-categories/${index}`,
					(payload) => {
						this.getCategoriesSuccess(payload, index);
						resolve(payload.data);
					},
					(error) => {
						this.getCategoriesError(error);
						reject(error);
					},
					this.load
				);
			});
		};

		getParentCategories = () => {
			return new Promise( (resolve, reject) => {
				Get(
					`/mywheels/find-parent-categories?type=video`,
					(payload) => {
						let data = payload.data;
						data.forEach(i => {
							let temp = {id: i.id, name: i.name, children: []}
							this.setState({categories: [...this.state.categories, temp]})
						});
						resolve(this.state.categories);
					},
					(error) => {
						this.getCategoriesError(error);
						reject(error);
					},
					this.load
				)
			})
		};

		getCategories = async () => {
			const parentCategories = await this.getParentCategories()
			let apiPromises = []
			parentCategories.forEach( i => {
				apiPromises.push(this.handleGetCategoryPromise(i.id))
			})

			Promise.all(apiPromises)
				.then((responses) => {
					const tempFlatten = responses.flat();
					let tempFiltered = _.filter(tempFlatten, (val) => val.parent_id);

					this.setState({
						flattenCategories: tempFiltered,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		};
		getCategoriesSuccess = (payload, id) => {
			let tempPayload = _.cloneDeep(this.state.categories);
			let tempIndex = _.findIndex(this.state.categories, {
				id,
			});

			tempPayload[tempIndex]["children"] = _.filter(
				payload.data,
				(val) => val.parent_id
			);

			return this.setState({
				categories: tempPayload,
			});
		};
		getCategoriesError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					onLoadCategory={this.state.loading}
					getCategories={this.getCategories}
					categories={this.state.categories}
					flattenCategories={this.state.flattenCategories}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
