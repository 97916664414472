import React from "react";
import { Button } from "reactstrap";
import { ModalFooter } from "reactstrap";

export default function CustomModalFooter({
	rightButton = [],
	leftButton = [],
}) {
	return (
		<ModalFooter>
			<div>
				{leftButton.map((button, buttonIndex) => (
					<Button
						key={`left_btn_${buttonIndex}`}
						color={button.color || "primary"}
						size={"xl"}
						onClick={() => button.onClick()}
						disabled={button.disabled || false}
						className={`${button.className || ""} ml-2`}
						style={{ ...button.style }}>
						{button.content}
					</Button>
				))}
			</div>
			<div className="ml-auto">
				{rightButton.map((button, buttonIndex) => (
					<Button
						key={`right_btn_${buttonIndex}`}
						color={button.color || "primary"}
						outline={button.outline}
						size={"xl"}
						disabled={button.disabled || false}
						onClick={() => button.onClick()}
						className={`${button.className || ""} ml-2`}
						style={{ ...button.style }}>
						{button.content}
					</Button>
				))}
			</div>
		</ModalFooter>
	);
}
