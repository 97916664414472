import React, { Component } from "react";
import { connect } from "react-redux";
import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import _ from "lodash";


const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			models: [],
			brands: [],
			searchParams: [
				{
					label: "Brand",
					value: `mywheels_car_brand_id`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				}]
		};

		onChangeModelHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getModel = (val) => {
			Get(
				`/mywheels/car-brand-models?${val}`,
				this.getModelSuccess,
				this.getModelError,
				this.load
			);
		};

		getModelSuccess = (payload) => this.setState({ models: payload.data.rows });
		getModelError = (error) => requestError(error);

		getBrand = () => {
			Get(
				`/mywheels/car-brands`,
				this.getBrandSuccess,
				this.getBrandError,
				this.load
			);
		};

		getBrandSuccess = (payload) => {
			let tempIndex = _.findIndex(
				this.state.searchParams,
				(val) => val.value === "mywheels_car_brand_id"
			);
			if (tempIndex > -1) {
				let tempSearchParams = _.cloneDeep(this.state.searchParams);
				tempSearchParams[tempIndex]["options"] = payload.data;
				this.onChangeModelHOC("searchParams", tempSearchParams);
			}
		};

		getBrandError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					searchParams={this.state.searchParams}
					models={this.state.models}
					onLoadModel={this.state.loading}
					getModel={this.getModel}
					getBrand={this.getBrand}
					onChangeModelHOC={this.onChangeModelHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
