import React, { useState, useEffect } from "react";
import _ from "lodash";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";
import { Input, FormGroup, Label, Form, Row, Col } from "reactstrap";
import { SampleData,variantFormData } from "./assets";
import LoadingIndicator from "components/Indicator/LoadingOverlay";

const ModelForm = ({
	models,
	selectedVariant,
	onSubmit,
	brands,
	onLoadImage,
}) => {
	const [tempForm, setTempForm] = useState(SampleData);
	const [filteredModel, setFilteredModel] = useState([]);
	const [carBrand, setCarBrand] = useState(0)

	useEffect(() => {
		if (selectedVariant) {
			setTempForm(selectedVariant);
			setFilteredModel(
				models.filter( i => i.mywheels_car_brand_id === selectedVariant?.car_brand_model?.brand_id)
			)
			setCarBrand(selectedVariant?.car_brand_model?.brand_id)  // To enable car model dropdown
		}
	}, [selectedVariant]);

	const onChangeField = (key, val) => {
		let temp = _.cloneDeep(tempForm);
		temp[key] = val;

		setTempForm(temp);
	};

	return (
		<>
			<ChinaPressModalBody>
				<Form>
					<Row>
						<Col xl={4}>
							<FormGroup>
								<Label>Title</Label>
								<Input
									value={tempForm["title"]}
									type={"text"}
									onChange={(e) => onChangeField("title", e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col lg={4}>
							<FormGroup>
								<Label>Car Brand</Label>
								<Input
									type={"select"}
									value={carBrand}
									onChange={(e) => {
											setCarBrand(e.target.value)
											setFilteredModel(
												models.filter( i => i.mywheels_car_brand_id === Number(e.target.value))
											)
										}
									}>
									<option value=""></option>
									{brands.map((brandChild) => (
										<option value={brandChild.id} key={brandChild.id}>
											{brandChild.name}
										</option>
									))}
								</Input>
							</FormGroup>
						</Col>
						<Col lg={4}>
							<FormGroup>
								<Label>Car Model</Label>
								<Input
									type={"select"}
									value={tempForm["mywheels_car_brand_model_id"]}
									onChange={(e) =>
										onChangeField("mywheels_car_brand_model_id", +e.target.value)
									}>
									<option value="">{carBrand ? "None" : "Select Car Brand"}</option>
									{carBrand && filteredModel.map((brandChild) => (
										<option value={brandChild.id} key={brandChild.id}>
											{brandChild.title}
										</option>
									))}
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>

							<FormGroup>
								<Label>
									Description
								</Label>
								<Input
									type="textarea"
									placeholder="Add description"
									value={tempForm["description"]}
									onChange={(e) => onChangeField("description", e.target.value)}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
					{variantFormData.map((form) => (
							<Col
								xl={form.type === "textarea" ? "12" : "4"}
								lg={form.type === "textarea" ? "12" : "6"}>
								<FormGroup key={`field_${form.key}`}>
									<Label>{form.label}</Label>
									{form.type !== "select" && (
										<Input
											type={form.type}
											value={tempForm[form.key]}
											onChange={(e) =>
												onChangeField(
													form.key,
													form.type !== "number"
														? e.target.value
														: +e.target.value
												)
											}
										/>
									)}
									{form.type === "select" && (
										<Input
											type={"select"}
											value={tempForm[form.key]}
											onChange={(e) =>
												onChangeField(
													form.key,
													e.target.value
												)
											}>
											<option value=""></option>
											{form.options.map((child) => (
												<option key={child} value={child}>
													{child}
												</option>
											))}
										</Input>
									)}
								</FormGroup>
							</Col>
						))}
					</Row>
				</Form>
			</ChinaPressModalBody>
			<ChinaPressModalFooter
				rightButton={[
					{
						color: "primary",
						disabled: !tempForm.mywheels_car_brand_model_id || !tempForm.title,
						content: "Submit",
						onClick: () => onSubmit(tempForm, selectedVariant?.id),
					},
				]}
			/>
			{onLoadImage && <LoadingIndicator />}
		</>
	);
};

export default ModelForm;
