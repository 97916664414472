import { useState } from "react";
import { BiZoomIn } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { UncontrolledTooltip, Button } from "reactstrap";

import { AiFillFileImage } from "react-icons/ai";
import _ from "lodash";

import NoDataImg from "assets/images/no-file.jpg";

import "./index.scss";

function DriveTable({
	mode,
	assets,
	multiSelect,

	onClickView,
	onClickEdit,
	onClickDelete,

	onSelect,
}) {
	const [tempSelected, setTempSelected] = useState([]);
	const [tempSelectedType, setSelectedType] = useState(null);

	const onHandleSelect = ({ url, type }) => {
		let temp = _.cloneDeep(tempSelected);
		
		let tempIndex = _.findIndex(tempSelected, val => val === url);
		
		if (tempIndex > -1) {
			temp.splice(tempIndex, 1);
		} else {
			temp.push(url);
		}

		setTempSelected(temp);
		setSelectedType(type);
	};

	return (
		<>
			{multiSelect && (
				<div
					className="bg-white d-flex py-2"
					style={{ position: "sticky", top: -16, zIndex: 999 }}>
					<Button
						color="primary"
						className="ml-auto"
						disabled={tempSelected.length < 1}
						onClick={() => onSelect(tempSelected, tempSelectedType)}>
						Submit
					</Button>
				</div>
			)}
			<div className="mywheels-drive_cont">
				<h5 className="mywheels-drive-title">Files</h5>
				{assets.length < 1 && (
					<div className={"text-center"} style={{ width: "max-content" }}>
						<img src={NoDataImg} style={{ width: 200 }} />
						<small className="d-block">No Data Available</small>
					</div>
				)}
				{assets?.[0] && (
					<div className="mywheels-drive-files_cont">
						{assets.map((file) => (
							<div
								key={`file${file.id}`}
								className="mywheels-drive_files-item"
								// onDoubleClick={() => onClickView(file)}
							>
								<div
									className="mywheels-drive_files-item-content_cont"
									style={{
										background: file.type === "video" ? "black" : "white",
									}}>
									{file.type === "video" && <video controls src={file.url} />}
									{file.type !== "video" && (
										<img
											style={{ objectFit: "cover" }}
											src={file.url}
											alt={`${file.alt}`}
										/>
									)}
									<div
										className="mywheels-drive_files_actions-cont"
										style={{
											...(mode === "view" && { transform: "translateX( 0% )" }),
										}}>
										{mode === "view" && (
											<div
												className={`mywheels-drive_files_action mywheels-drive_files_action-text ${
													tempSelected.includes(file.url)
														? "bg-primary"
														: "bg-secondary"
												}`}
												onClick={() => {
													if (!multiSelect) {
														return onSelect(file.url);
													}

													onHandleSelect(file);
												}}>
												<span className="text-white">
													{tempSelected.includes(file.url)
														? "Selected"
														: "Select"}
												</span>
											</div>
										)}
										{mode === "edit" && (
											<>
												<div
													className="mywheels-drive_files_action mywheels-drive_files_action-icon bg-danger"
													id="delete"
													onClick={() => onClickDelete(file)}>
													<FaTrash />
													<UncontrolledTooltip target="delete">
														Delete
													</UncontrolledTooltip>
												</div>
												<div
													className="mywheels-drive_files_action mywheels-drive_files_action-icon bg-primary"
													id="edit"
													onClick={() => onClickEdit(file)}>
													<MdModeEditOutline />
													<UncontrolledTooltip target="edit">
														Edit
													</UncontrolledTooltip>
												</div>
											</>
										)}
										<div
											className="mywheels-drive_files_action bg-warning mywheels  mywheels-drive_files_action-icon-hover"
											onClick={() => onClickView(file)}>
											<BiZoomIn />
										</div>
									</div>
								</div>
								<div className="mywheels-drive-details">
									<AiFillFileImage className="text-danger" />
									<span>{file.title}</span>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
}
export default DriveTable;
