import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import TagForm from "./Form.js";

import TagHOC from "./action";

class Tag extends Component {
	componentDidMount = () => this.props.getTag();

	renderCreateTag = () => {
		return (
			<ChinaPressModal
				size={"md"}
				isOpen={this.props.showCreateTagModal}
				onClose={() => this.props.onChangeTagHOC("showCreateTagModal", false)}>
				<TagForm onSubmit={this.props.createTag} />
			</ChinaPressModal>
		);
	};

	renderEditTag = () => {
		return (
			<ChinaPressModal
				size={"md"}
				isOpen={this.props.showEditTagModal}
				onClose={() => {
					this.props.onChangeTagHOC("selectedTag", null);
					this.props.onChangeTagHOC("showEditTagModal", false);
				}}>
				<TagForm
					selectedTag={this.props.selectedTag}
					onSubmit={this.props.updateTag}
				/>
			</ChinaPressModal>
		);
	};

	renderTagTable = () => {
		return (
			<MyWheelsTable
				data={this.props.tags}
				columnsContent={[
					{
						Header: "Name",
						accessorKey: "name",
						centerColumn: true,
						Cell: (val) => <>{val.name}</>,
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditPost",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.onChangeTagHOC("selectedTag", val);
							this.props.onChangeTagHOC("showEditTagModal", true);
						},
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading="Tags"
					subheading="Listings of all the tags present in the system."
					icon="pe-7s-ticket icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeTagHOC("showCreateTagModal", true);
							},
							content: (
								<>
									<span>Create Tag</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<Card className="main-card mb-3">
								<CardBody>{this.renderTagTable()}</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreateTag()}
				{this.renderEditTag()}
				{this.props.onLoadTag && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(TagHOC)(Tag);
