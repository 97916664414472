import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			brands: [],
			models: [],
			variants: [],
			selectedVariant: {},
			showCreateVariantModal: false,
			showEditVariantModal: false,
			searchParams: [
				{
					label: "Car Brand",
					value: `brand`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				},
				{
					label: "Car Model",
					value: `model`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				},
			],
		};

		onChangeModelHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBrand = () => {
			Get(
				`/mywheels/car-brands`,
				this.getBrandSuccess,
				this.getBrandError,
				this.load
			);
		};
		getBrandSuccess = (payload) => this.setState({ brands: payload.data });
		getBrandError = (error) => requestError(error);

		getModel = () => {
			Get(
				`/mywheels/car-brand-models`,
				this.getModelSuccess,
				this.getModelError,
				this.load
			);
		};
		getModelSuccess = (payload) => this.setState({ models: payload.data.rows });
		getModelError = (error) => requestError(error);

		getVariant = (val) => {
			Get(
				`/mywheels/car-model-variants?${val}`,
				this.getVariantSuccess,
				this.getVariantError,
				this.load
			);
		};

		getVariantSuccess = (payload) => this.setState({ variants: payload.data });
		getVariantError = (error) => requestError(error);

		createVariant = (dataToSubmit) =>
			Post(
				`/mywheels/car-model-variants`,
				dataToSubmit,
				(payload) => this.createVariantSuccess(payload),
				this.createVariantError,
				this.load
			);
		createVariantSuccess = (payload) => {
			this.setState({ showCreateVariantModal: false });
			this.getVariant();
			requestSuccess("Car Variant has been created successfully.");
		};
		createVariantError = (error) => requestError(error);

		updateVariant = (dataToSubmit, id) =>
			Put(
				`/mywheels/car-model-variants/${id}`,
				dataToSubmit,
				this.updateVariantSuccess,
				this.updateVariantError,
				this.load
			);
		updateVariantSuccess = (payload) => {
			this.getVariant();
			this.setState({ showEditVariantModal: false });
			requestSuccess("Model has been updated successfully.");
		};
		updateVariantError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					brands={this.state.brands}
					models={this.state.models}
					variants={this.state.variants}
					selectedVariant={this.state.selectedVariant}
					onLoadModel={this.state.loading}
					showCreateVariantModal={this.state.showCreateVariantModal}
					showEditVariantModal={this.state.showEditVariantModal}
					searchParams={this.state.searchParams}
					getModel={this.getModel}
					getVariant={this.getVariant}
					createVariant={this.createVariant}
					getBrand={this.getBrand}
					updateVariant={this.updateVariant}
					onChangeModelHOC={this.onChangeModelHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
